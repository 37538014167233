<template>
  <div class="bg-washed-white border-2 border-p-black md:p-6 p-4 w-full">
    <h4>{{ this.show.showtime }}</h4>
    <div
      class="details grid md:grid-cols-9 grid-cols-1 justify-between gap-x-4 md:mt-2 mt-1"
    >
      <div class="flex flex-col col-span-4">
        <p class="font-semibold">{{ this.show.title }}</p>
        <p class="text-p-black opacity-60">
          {{ this.show.description }}
        </p>
      </div>
      <div class="col-span-2">
        <span class="font-bold tracking-wide text-base">{{
          this.show.address
        }}</span>
      </div>
      <div
        class="text-right flex flex-col gap-2 col-span-3 md:mt-0 mt-4 lg:pl-10 md:pl-6"
      >
        <div v-if="showStreamButton && this.show.ticket_links">
          <a
            target="_blank"
            :href="this.show.ticket_links.internal"
            class="btn yellow has-border small w-full text-center flex justify-center"
          >
            Stream From the App
          </a>
        </div>
        <div v-if="showInPersonButton && this.show.ticket_links">
          <a
            target="_blank"
            :href="this.show.ticket_links.thirdPartyTicketingWebsite"
            class="btn cream-white small w-full flex text-center justify-center"
          >
            In Person Ticket
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    show: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    showStreamButton() {
      if (this.show) {
        if (
          this.show.location === "online" ||
          this.show.location === "hybrid"
        ) {
          return true;
        }
        return false;
      }
      return false;
    },
    showInPersonButton() {
      if (this.show) {
        if (
          this.show.location === "inperson" ||
          this.show.location === "hybrid"
        ) {
          return true;
        }
        return false;
      }
      return false;
    }
  },
};
</script>
