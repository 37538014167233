<template>
  <section
    class="lg:w-3/5 container mx-auto md:p-0 px-4 my-32 relative flex justify-center items-center flex-col"
  >
    <div class="w-full mb-8 text-center">
      <h1 class="my-4 text-black">Don’t have a Philippe Saisse account?</h1>
      <p>
        New users, create your account and start your membership here. Please
        enter a valid phone number below.
      </p>
      <div
        class="md:mt-12 mt-6 flex flex-col md:flex-row md:items-center items-start md:justify-center"
      >
        <GetStartedForm button-text="Sign Up" :isDark="true" />
      </div>
    </div>
  </section>
</template>
<script>
import GetStartedForm from "../components/GetStartedForm.vue";
import { useHead } from "@unhead/vue";
import { signUpMetaTags } from "../meta/metaData";
export default {
  setup() {
    useHead({
      meta: signUpMetaTags(),
    });
  },
  components: {
    GetStartedForm,
  },
  mounted() {
    document.body.classList.add("bg-cream-white");
    document.body.classList.remove("bg-p-black");
  },
};
</script>