<template>
  <form
    @submit.prevent="getStarted"
    class="flex md:flex-row flex-col md:w-auto w-full"
  >
    <span
      class="mb-2 md:hidden block text-base font-semibold leading-5 text-cream-white"
      :class="isDark ? 'text-black' : 'text-cream-white'"
    >
      Phone
    </span>
    <input
      required
      v-model="form.tel"
      class="bg-transparent lg:w-96 w-full border-2 md:border-r-0 md:py-3 py-2 px-6 md:mb-0 mb-3"
      :class="
        isDark
          ? 'border-black text-black placeholder-gray-700'
          : 'border-dark-cream text-dark-cream'
      "
      type="text"
      placeholder="+1 (555) 123 4567"
    />
    <button
      class="btn yellow whitespace-nowrap md:w-44"
      :class="{ 'has-border': isDark }"
    >
      <SvgSpinner v-if="busy" />
      <span v-else>Get Started</span>
    </button>
  </form>
</template>
<script>
import { requestOTP } from "@/services/SessionService";
import { useToast } from "vue-toastification";
import { useSessionStore } from "@/stores/session";
import SvgSpinner from "@/components/svg/Spinner.vue";
import { parsePhoneNumber } from "libphonenumber-js";

export default {
  components: {
    SvgSpinner,
  },
  setup() {
    const session = useSessionStore();
    const toast = useToast();
    return { session, toast };
  },
  props: {
    isDark: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        tel: "",
      },
      busy: false,
    };
  },
  methods: {
    async getStarted() {
      const parsedPhoneNumber = parsePhoneNumber(this.form.tel, "US");
      if (parsedPhoneNumber.isPossible() === false) {
        this.toast.error("Make sure you're using a valid phone number.");
        this.loading = false;
        return;
      }

      this.busy = true;
      await requestOTP({tel:parsedPhoneNumber.number})
        .then(
          () => {
            this.session.setUnverifiedTel(parsedPhoneNumber.number);
            this.$router.push(`/code`);
          },
          ({ status }) => this.toast.error("Oops.. something went wrong.")
        )
        .finally(() => (this.busy = false));
    },
  },
};
</script>
