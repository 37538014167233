<template>
  <div>
    <img class="w-full" :src="imgUrl" :alt="imgAlt" />
    <div class="text-center mt-2">
      <p class="sub-heading text-2xl font-bold text-cream-white">
        {{ title }}
      </p>
      <p class="text-cream-white">
        {{ description }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    imgName: {
      type: String,
      default: "",
    },
    imgAlt: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
  },
  computed: {
    imgUrl() {
      return new URL(`../assets/images/${this.imgName}`, import.meta.url).href;
    },
  },
};
</script>
