<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 14.7524C18.279 11.8784 18.5535 5.45251 18.5535 5.45251C18.5535 5.45251 12.1215 5.72686 9.25349 3.00586L5.71347 6.54602C7.67097 8.39852 11.277 8.86197 13.3785 8.96997C8.99399 11.8305 3.00598 15.3212 3.00598 15.3212L8.69099 21.0059C8.69099 21.0059 12.1815 15.0182 15.042 10.6337C15.15 12.7337 15.6135 16.3335 17.466 18.2985L21 14.7524Z"
      :fill="fill"
    />
  </svg>
</template>
<script>
export default {
  props: {
    fill: {
      type: String,
      default: "#FFD600",
    },
  },
};
</script>
