<template>
  <main>
    <!-- Hero -->
    <section class="philippe-bg md:pt-20 pt-12 md:px-0 px-4 md:mb-0 mb-12">
      <div
        class="container mx-auto lg:w-8/12 md:w-5/6 w-full md:pt-20 pt-10 md:pb-24"
      >
        <div class="grid lg:grid-cols-2 gap-12 items-center">
          <div>
            <div class="flex md:justify-start justify-center">
              <h1 class="text-washed-white mb-4">
                <img
                  src="@/assets/svg/logo.svg"
                  class="w-72"
                  alt="Philippe Saisse logo"
                />
              </h1>
            </div>
            <p
              class="sub-heading text-white font-bold tracking-wide md:mb-8 mb-4"
            >
              Unlimited access to Philippe’s music, technique, and more.
            </p>
            <p class="sub-heading md:mt-6 text-white md:mb-8 mb-4">
              Welcome to a fully immersive, members-only community catered to
              world-class Jazz Musician and Producer, Philippe Saisse. Engage
              and access exclusive content, in-app 4K live-streams and connect
              with Philippe and an entourage of grammy-winning musicians as they
              collaborate to bring you new and exciting music, videos and never
              before seen content.
            </p>
            <p class="sub-heading text-white mt-4 mb-8">
              Ready to join? Enter your phone number to create an account and start
              your membership.
            </p>
            <div class="md:mt-0 mt-4">
              <GetStartedForm></GetStartedForm>
            </div>
          </div>
          <div class="flex justify-center gap-8 md:px-0 px-6">
            <div class="xl:w-full xl:h-full lg:w-10/12 md:w-full lg:py-10">
              <img
                src="@/assets/images/app-img.webp"
                alt="First Philippe Saisse App"
                class="w-full md:h-full"
              />
            </div>
            <div class="xl:w-full xl:h-full lg:w-10/12 md:w-full lg:py-10">
              <img
                src="@/assets/images/app-img-2.webp"
                alt="Second Philippe Saisse App"
                class="w-full md:h-full"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="lg:p-0 px-4">
      <!-- Content -->
      <section class="lg:bg-solid-black lg:px-40">
        <div class="lg:py-20 py-10 border-t-2 border-dark-cream">
          <h2 class="md:mb-16 mb-10 text-cream-white text-center">
            Highlights
          </h2>
          <div class="grid grid-cols-12 md:gap-x-12 md:gap-y-0 gap-y-10">
            <div class="md:col-span-3 sm:col-span-6 col-span-12 md:px-0 px-6">
              <HighlightCard
                imgName="sessions.webp"
                imgAlt="A Collage of images of various artists"
                title="Dis-Arranger Deconstructions"
                description="Watch along as I record and produce a new track, one layer
                    at a time."
              />
            </div>
            <div class="md:col-span-3 sm:col-span-6 col-span-12 md:px-0 px-6">
              <HighlightCard
                imgName="reward.webp"
                imgAlt="A graphic of a man reading the Daily Bugle."
                title="Cracking the Vault"
                description="I’ll be sharing a new video each month featuring historic
                    performances, concerts and collaborations."
              />
            </div>
            <div class="md:col-span-3 sm:col-span-6 col-span-12 md:px-0 px-6">
              <HighlightCard
                imgName="daily.webp"
                imgAlt="A graphic of a man exploring the outside of a cave."
                title="The Daily"
                description="Catch my everyday happenings. Discover exclusive content and
                    drops including live shows, events and merchandise."
              />
            </div>
            <div class="md:col-span-3 sm:col-span-6 col-span-12 md:px-0 px-6">
              <HighlightCard
                imgName="funk.webp"
                imgAlt="A graphic of a Philippe Saisse sitting on a chair."
                title="Keep the Funk Alive"
                description="Tune in as I collaborate with my closest friends in the
                    industry on some funkadelic tunes."
              />
            </div>
          </div>
          <div
            class="flex flex-col items-center justify-center md:mt-16 mt-8 text-cream-white"
          >
            <h3 class="text-cream-white text-center">$5 a month</h3>
            <div class="my-4 text-center">
              <p class="md:mb-8 mb-4">
                Philippe Saisse App subscription starts at $5 per month, or $50
                per year.
              </p>
              <p class="md:mb-8 mb-4">
                Subscribe to get access to all of Philippe’s premium content.
              </p>
              <p class="md:mb-8 mb-4">Cancel anytime.</p>
            </div>
            <div class="mb-8">
              <a class="btn cream-white" href="/">Learn More</a>
            </div>
            <GetStartedForm></GetStartedForm>
          </div>
        </div>
        <div
          class="lg:py-20 py-10 border-t-2 border-dark-cream text-cream-white"
        >
          <h2 class="md:mb-6 mb-3 text-cream-white text-center">Channels</h2>
          <p class="text-cream-white text-center">
            Philippe Saisse App subscription starts at $5 per month, or $50 per
            year.
          </p>
          <div class="md:my-12 mt-6 mb-2 lg:w-10/12 w-full mx-auto">
            <swiper
              id="channel-slider"
              :breakpoints="{
                480: {
                  slidesPerView: 1,
                  spaceBetween: 4,
                },
                769: {
                  slidesPerView: 4,
                  spaceBetween: 10,
                },
                1200: {
                  slidesPerView: 5,
                  spaceBetween: 50,
                },
              }"
              grabCursor
              :loop="true"
              :navigation="true"
              :modules="modules"
              @swiper="onSwiper"
              @slideChange="onSlideChange"
            >
              <swiper-slide>
                <div class="text-center">
                  <img
                    src="@/assets/images/good-guys-slide.webp"
                    alt="A group of men with Philippe posing for a picture."
                    class="rounded-full mx-auto mb-4"
                  />
                  <h4 class="mb-3">Inside Valerian Studio</h4>
                  <p>
                    Welcome to my studio, my laboratory and playground. Be a fly
                    on the wall while I work on different projects from start to
                    finish and witness my process.
                  </p>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="text-center">
                  <img
                    src="@/assets/images/diabolik-slide.webp"
                    alt="A record of a woman being shown."
                    class="rounded-full mx-auto mb-4"
                  />
                  <h4 class="mb-3">Diabolik</h4>
                  <p>
                    I’m taking you inside my collections of art, action figures
                    and comic books from around the globe.
                  </p>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="text-center">
                  <img
                    src="@/assets/images/mask-slide.webp"
                    alt="A person wearing a ski mask."
                    class="rounded-full mx-auto mb-4"
                  />
                  <h4 class="mb-3">Taking off the Mask</h4>
                  <p>
                    From B-Sides to fully archived sessions, listen in as I work
                    to bring new life to shelved projects
                  </p>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="text-center">
                  <img
                    src="@/assets/images/funk-slide.webp"
                    alt="A collage of various artists playing instruments."
                    class="rounded-full mx-auto mb-4"
                  />
                  <h4 class="mb-3">Keep The Funk Alive</h4>
                  <p>
                    This special space will be reserved for building a Global
                    Community-Organized Music Project crafted by Artists
                    throughout the world and on.
                  </p>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="text-center">
                  <img
                    src="@/assets/images/chez-slide.webp"
                    alt="A graphic portrait of Philippe Saisse."
                    class="rounded-full mx-auto mb-4"
                  />
                  <h4 class="mb-3">Dis-Arranger Deconstructions</h4>
                  <p>
                    Watch along as I record and produce a new track, one layer
                    at a time
                  </p>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="text-center">
                  <img
                    src="@/assets/images/reward.webp"
                    alt="A graphic of a person reading the newspaper."
                    class="rounded-full mx-auto mb-4"
                  />
                  <h4 class="mb-3">The Daily</h4>
                  <p>
                    Catch my everyday happenings. Discover exclusive content and
                    drops including live shows, events and merch.
                  </p>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="text-center">
                  <img
                    src="@/assets/images/vault-slide.webp"
                    alt="A man walking in front of a rock."
                    class="rounded-full mx-auto mb-4"
                  />
                  <h4 class="mb-3">Cracking the Vault</h4>
                  <p>
                    I’ll be sharing a new video each month featuring historic
                    performances, concerts and collabs.
                  </p>
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      <div class="lg:py-20 pt-10 border-t-2 border-dark-cream">
          <h2 class="md:mb-6 mb-3 text-cream-white text-center">Content</h2>
          <div class="md:w-8/12 w-full mx-auto">
            <p class="text-cream-white text-center">
              The Philippe Saisse App offers subscribers full access to all
              sorts of exclusive media content, such as Audio posts, Music
              Videos, Behind the Scenes Series, 4K Live Shows, and much more not
              available on any other platform.
            </p>
          </div>
          <div class="md:my-12 my-6">
            <swiper
              id="content-slider"
              :spaceBetween="15"
              :loop="true"
              grabCursor
              @swiper="onSwiper"
              @slideChange="onSlideChange"
              :breakpoints="{
                320: {
                  slidesPerView: 4,
                  spaceBetween: 5,
                },
                480: {
                  slidesPerView: 4,
                  spaceBetween: 5,
                },
                769: {
                  slidesPerView: 6,
                  spaceBetween: 10,
                },
                1200: {
                  slidesPerView: 8,
                  spaceBetween: 15,
                },
              }"
            >
              <swiper-slide>
                <div>
                  <img
                    src="@/assets/images/ps-avatar-19.webp"
                    alt="Graphic of colorful superhero playing a musical instrument"
                  />
                </div>
              </swiper-slide>
              <swiper-slide>
                <div>
                  <img
                    src="@/assets/images/ps-avatar-09.webp"
                    alt="A graphic of a man playing the saxophone"
                  />
                </div>
              </swiper-slide>
              <swiper-slide>
                <div>
                  <img
                    src="@/assets/images/ps-avatar-20.webp"
                    alt="A woman in a red dress playing the guitar."
                  />
                </div>
              </swiper-slide>
              <swiper-slide>
                <div>
                  <img
                    src="@/assets/images/ps-avatar-18.webp"
                    alt="A graphic of a man in pink playing the saxophone."
                  />
                </div>
              </swiper-slide>
              <swiper-slide>
                <div>
                  <img
                    src="@/assets/images/ps-avatar-16.webp"
                    alt="A graphic a woman playing the guitar."
                  />
                </div>
              </swiper-slide>
              <swiper-slide>
                <div>
                  <img
                    src="@/assets/images/ps-avatar-17.webp"
                    alt="A graphic of a large male playing the piano."
                  />
                </div>
              </swiper-slide>
              <swiper-slide>
                <div>
                  <img
                    src="@/assets/images/ps-avatar-15.webp"
                    alt="A woman in a mask playing the guitar."
                  />
                </div>
              </swiper-slide>
              <swiper-slide>
                <div>
                  <img
                    src="@/assets/images/ps-avatar-14.webp"
                    alt="A man in a suit playing the saxophone"
                  />
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>
<style>
.philippe-bg {
  background-image: url("@/assets/images/bg-philippe.webp");
  background-position: 50% 0%;
  background-size: cover;
}

@media only screen and (max-width: 769px) {
  .philippe-bg {
    background-position: 25% 0%;
  }
}

@media only screen and (min-width: 768px) {
  #channel-slider .swiper-wrapper {
    margin-left: 51%;
  }
}

.swiper-button-prev,
.swiper-button-next {
  color: #ffd600 !important;
}

@media only screen and (min-width: 769px) {
  .swiper-button-prev,
  .swiper-button-next {
    display: none !important;
  }
}

#content-slider .swiper-wrapper {
  margin-left: 32%;
}
</style>
<script>
import GetStartedForm from "@/components/GetStartedForm.vue";
import HighlightCard from "@/components/HighlightCard.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import { useHead } from "@unhead/vue";
import { homeMetaTags } from "../meta/metaData";
import MicroModal from "micromodal";
import { useCookies } from "vue3-cookies";
export default {
  setup() {
    useHead({
      meta: homeMetaTags(),
    });
    const { cookies } = useCookies();

    return {
      cookies,
      modules: [Navigation],
    };
  },
  components: {
    GetStartedForm,
    HighlightCard,
    Swiper,
    SwiperSlide
  },
  async mounted() {
    document.body.classList.add("bg-p-black");
    document.body.classList.remove("bg-cream-white");

    if (
      this.$route.query?.success &&
      !this.cookies.get("subscribed-modal-shown")
    ) {
      MicroModal.show("subscribed");
      this.cookies.set("subscribed-modal-shown", true);
    }
  },
};
</script>
