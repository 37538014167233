<template>
  <footer
    class="flex justify-between absolute bottom-0 right-0 left-0 items-start md:py-12 py-6 md:px-16 px-4 footer-bg"
  >
    <div>
      <div class="md:mb-3 mb-2">
        <a
          href="mailto:support@saissemedia.com"
          class="md:text-lg text-sm leading-6 font-semibold md:mb-0 mb-4"
          :class="classFor('links')"
        >
          Questions? Contact us.
        </a>
      </div>
      <div class="flex md:mb-4 mb-3">
        <a
          target="_blank"
          aria-label="Explore more Facebook content of Philippe Saisse."
          href="https://www.facebook.com/philippe.saisse/"
          class="md:mr-4 mr-2"
        >
          <SocialIcon
            class="md:w-auto w-6"
            :fill="isHome ? 'white' : 'black'"
            type="fb"
          />
        </a>
        <a
          target="_blank"
          aria-label="Explore more Instagram content of Philippe Saisse."
          href="https://www.instagram.com/philippesaisse/"
          class="md:mr-4 mr-2"
        >
          <SocialIcon
            class="md:w-auto w-6"
            :fill="isHome ? 'white' : 'black'"
            type="ig"
          />
        </a>
        <a
          target="_blank"
          aria-label="Explore more Twitter content of Philippe Saisse."
          href="https://twitter.com/philippesaisse"
        >
          <SocialIcon
            class="md:w-auto w-6"
            :fill="isHome ? 'white' : 'black'"
            type="twitter"
          />
        </a>
      </div>
      <div class="flex">
        <a
          target="_blank"
          href="https://apps.apple.com/us/app/philippe-saisse/id6444134222"
        >
          <img
            class="md:w-28 w-20 h-full md:mr-4 mr-2"
            src="@/assets/images/app-store.png"
            alt="Download on the App Store Icon"
          />
        </a>
        <a
          target="_blank"
          href="https://play.google.com/store/apps/details?id=com.quevis.psaisse.dev"
        >
          <img
            class="md:w-28 w-20 h-full"
            src="@/assets/images/google-play.png"
            alt="Get on Google Play Icon"
          />
        </a>
      </div>
    </div>
    <div class="flex flex-col text-right" :class="classFor('links')">
      <a
        target="_blank"
        href="https://saissemedia.zendesk.com/hc/en-us"
        class="md:text-base text-sm font-normal leading-5 md:mb-4 mb-2"
      >
        Saisse Support Center
      </a>
      <RouterLink
        to="/privacy"
        class="md:text-base text-sm font-normal leading-5 md:mb-4 mb-2"
      >
        Privacy Policy
      </RouterLink>
      <RouterLink
        to="/terms-of-service"
        class="md:text-base text-sm font-normal leading-5"
      >
        Terms of Service
      </RouterLink>
    </div>
  </footer>
</template>
<script>
import SocialIcon from "@/components/svg/SocialIcon.vue";
export default {
  components: {
    SocialIcon,
  },
  computed: {
    isHome() {
      return this.$route.path === "/";
    },
  },
  methods: {
    classFor(scope) {
      switch (scope) {
        case "links":
          return this.isHome ? "text-white" : "";
        default:
          return "";
      }
    },
  },
};
</script>
