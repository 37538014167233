import axios from "axios";

const baseURL = import.meta.env.VITE_API_URL;
const namespace = import.meta.env.VITE_API_NAMESPACE;

axios.defaults.withCredentials = true;
const svc = axios.create({
  credentials: true,
  baseURL,
});

export const isOTP = (function () {
  const re = /^[0-9]{6}$/;
  return function (s) {
    return re.test(s);
  };
})();

export async function requestOTP({ tel }) {
  return new Promise(async (resolve, reject) => {
    try {
      const data = { tel, namespace };
      const res = await svc.post("/sessions", data);
      const status = res?.status;
      if (status === 200) {
        resolve();
        return;
      }
      reject({ status });
    } catch (err) {
      const status = err?.response?.status;
      console.log(err);
      reject({ status });
    }
  });
}

export async function login({ code }) {
  return new Promise(async (resolve, reject) => {
    try {
      const data = { namespace, code };
      const res = await svc.post("/login", data);

      const status = res?.status;
      const payload = res.data;
      const signup_checkout_url = payload.data?.signup_checkout_url;
      if (status === 200) {
        const data = await fetchProfile();
        const profileId = data.profile.id;
        if (profileId && profileId !== undefined) {
          payload.profile = { id: profileId, name: data.profile.name };
        }

        if (signup_checkout_url) {
          // Fetch Stripe Checkout URL
          await axios.post(signup_checkout_url).then((data) => {
            payload.data.checkout_session_url = data.data.url;
            resolve(payload);
          });
        } else {
          resolve(payload);
        }
      }
    } catch (err) {
      const status = err?.response?.status;
      if (status === 401) {
        reject({ status, unauthorized: true });
      }
      console.log(err);
      reject({ status });
    }
  });
}

export async function fetchProfile() {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await svc.get(`/account`);
      const status = res?.status;
      if (status === 200) {
        resolve({ profile: res.data });
        return;
      }
      reject({ status });
    } catch (err) {
      const status = err?.response?.status;
      if (status === 401) {
        reject({ status, unauthorized: true });
        return;
      }
      console.log(err);
      reject({ status });
    }
  });
}

export async function enroll(profile) {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await svc.post("/profiles", profile);
      const status = res?.status;
      if (status === 200) {
        const profileId = res.data.id;
        resolve({ profileId });
        return;
      }

      if (status === 202) {
        let profileUrl = res?.headers?.link;
        profileUrl = profileUrl.slice(
          profileUrl.indexOf("<") + 1,
          profileUrl.lastIndexOf(">")
        );
        await svc.get(profileUrl).then((profile) => {
          const profileId = profile.id;
          resolve({ profileId });
          return;
        });
      }
      reject({ status });
    } catch (err) {
      const status = err?.response?.status;
      if (status === 401) {
        reject({ status, unauthorized: true });
        return;
      }
      console.log(err);
      reject({ status });
    }
  });
}

export async function updateProfile({ namespace, profileId, tel, email }) {
  return new Promise(async (resolve, reject) => {
    try {
      const data = { namespace, profileId, tel, email };
      const res = await svc.patch(`/profiles/${profileId}`, data);
      const status = res?.status;
      if (status === 200) {
        resolve();
        return;
      }
      reject({ status });
    } catch (err) {
      const status = err?.response?.status;
      if (status === 401) {
        reject({ status, unauthorized: true });
        return;
      }
      console.log(err);
      reject({ status });
    }
  });
}

export async function createStripeCheckoutSession() {
  return new Promise(async (resolve, reject) => {
    try {
      const data = {};
      const res = await svc.post("/create-checkout-session", data);
      const status = res?.status;
      if (status === 200) {
        resolve(res);
        return;
      }
      reject({ status });
    } catch (err) {
      const status = err?.response?.status;
      if (status === 401) {
        reject({ status, unauthorized: true });
        return;
      }
      console.log(err);
      reject({ status });
    }
  });
}

export async function createStripePortalSession({ customer, return_url }) {
  return new Promise(async (resolve, reject) => {
    try {
      const data = { customer, return_url };
      const res = await svc.post("/create-portal-session", data);
      const status = res?.status;
      if (status === 200) {
        resolve(res);
        return;
      }
      reject({ status });
    } catch (err) {
      const status = err?.response?.status;
      if (status === 401) {
        reject({ status, unauthorized: true });
        return;
      }
      console.log(err);
      reject({ status });
    }
  });
}

export async function fetchShows() {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await svc.get("/shows");
      const status = res?.status;
      if (status === 200) {
        resolve({ res });
        return;
      }
      reject({ status });
    } catch (err) {
      const status = err?.response?.status;
      if (status === 401) {
        reject({ status, unauthorized: true });
        return;
      }
      reject({ status });
    }
  });
}
