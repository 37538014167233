<template>
  <section
    class="md:mt-24 mt-16 md:w-6/12 lg:w-4/12 container mx-auto md:p-0 px-8 relative flex justify-center items-center flex-col"
  >
    <div class="w-full mb-8">
      <h1>Page not found</h1>
      <p class="my-4">
        This page doesn't exist or was removed. We suggest you go back home.
      </p>
      <div class="py-4">
        <RouterLink to="/" class="btn yellow">Back to Home</RouterLink>
      </div>
    </div>
  </section>
</template>
