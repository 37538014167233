import { ref, computed } from "vue";
import { defineStore } from "pinia";
import { useCookies } from "vue3-cookies";

const sessionIdKeyName = "quevis_session_id";

const { cookies } = useCookies();

export const useSessionStore = defineStore("session", {
  state: () => ({
    name: false,
    tel: false,
    telVerified: false,
    email: false,
    emailVerified: false,
    profileId: false,
    stripeCheckoutUrl: false,
  }),
  getters: {
    sessionId(state) {
      if (cookies.isKey(sessionIdKeyName) === true) {
        const id = cookies.get(sessionIdKeyName);
        return id;
      }
      return false;
    },
  },
  actions: {
    setUnverifiedEmail(email) {
      this.email = email;
      this.emailVerified = false;
    },
    clearEmail(email) {
      this.email = false;
      this.emailVerified = false;
    },
    setUnverifiedTel(tel) {
      this.tel = tel;
      this.telVerified = false;
    },
    clearTel(tel) {
      this.tel = false;
      this.telVerified = false;
    },
    setName(name) {
      this.name = name;
    },
    setProfileId(profileId) {
      this.telVerified = true;
      this.profileId = profileId;
    },
    setStripeCheckoutUrl(url) {
      this.stripeCheckoutUrl = url;
    },
    abandonSession() {
      this.$reset();
      cookies.remove(sessionIdKeyName);
    },
  },
  persist: true,
});
