<template>
  <div>
    <svg
      v-if="type === 'twitter'"
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.1786 4.88453C21.3931 5.22336 20.5383 5.46723 19.6578 5.5622C20.5719 5.01913 21.2565 4.16115 21.583 3.14926C20.7252 3.65954 19.7855 4.01736 18.8056 4.20685C18.396 3.76899 17.9006 3.42017 17.3504 3.18213C16.8001 2.94409 16.2067 2.82193 15.6071 2.82325C13.1813 2.82325 11.2305 4.78955 11.2305 7.20249C11.2305 7.54133 11.2715 7.88017 11.3383 8.20618C7.70601 8.01622 4.4665 6.28095 2.31282 3.62415C1.9204 4.29442 1.71475 5.05761 1.71729 5.8343C1.71729 7.35394 2.48994 8.6939 3.66818 9.48196C2.97383 9.45461 2.29573 9.26376 1.68905 8.92493V8.97883C1.68905 11.1068 3.19329 12.8704 5.19809 13.2759C4.82167 13.3737 4.43444 13.4237 4.04552 13.4248C3.76059 13.4248 3.49106 13.3966 3.21896 13.3581C3.77342 15.0933 5.38804 16.3537 7.3107 16.3948C5.80646 17.573 3.92231 18.2661 1.87644 18.2661C1.50936 18.2661 1.17052 18.2533 0.818848 18.2122C2.75947 19.4572 5.06204 20.1759 7.54173 20.1759C15.5917 20.1759 19.9966 13.507 19.9966 7.71845C19.9966 7.5285 19.9966 7.33854 19.9838 7.14859C20.836 6.52482 21.583 5.75216 22.1786 4.88453Z"
        :fill="fill"
      />
    </svg>
    <svg
      v-if="type === 'ig'"
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5001 8.07841C9.6159 8.07841 8.07829 9.61603 8.07829 11.5002C8.07829 13.3843 9.6159 14.9219 11.5001 14.9219C13.3842 14.9219 14.9218 13.3843 14.9218 11.5002C14.9218 9.61603 13.3842 8.07841 11.5001 8.07841ZM21.7628 11.5002C21.7628 10.0832 21.7756 8.67908 21.696 7.26469C21.6165 5.62183 21.2417 4.16379 20.0403 2.96245C18.8364 1.75855 17.381 1.38634 15.7381 1.30676C14.3211 1.22719 12.917 1.24002 11.5026 1.24002C10.0857 1.24002 8.68153 1.22719 7.26713 1.30676C5.62427 1.38634 4.16624 1.76112 2.9649 2.96245C1.76099 4.16636 1.38878 5.62183 1.3092 7.26469C1.22963 8.68165 1.24246 10.0858 1.24246 11.5002C1.24246 12.9146 1.22963 14.3213 1.3092 15.7357C1.38878 17.3785 1.76356 18.8366 2.9649 20.0379C4.1688 21.2418 5.62427 21.614 7.26713 21.6936C8.68409 21.7732 10.0882 21.7603 11.5026 21.7603C12.9196 21.7603 14.3237 21.7732 15.7381 21.6936C17.381 21.614 18.839 21.2392 20.0403 20.0379C21.2442 18.834 21.6165 17.3785 21.696 15.7357C21.7782 14.3213 21.7628 12.9171 21.7628 11.5002ZM11.5001 16.765C8.58655 16.765 6.23521 14.4137 6.23521 11.5002C6.23521 8.58667 8.58655 6.23533 11.5001 6.23533C14.4136 6.23533 16.7649 8.58667 16.7649 11.5002C16.7649 14.4137 14.4136 16.765 11.5001 16.765ZM16.9805 7.24929C16.3003 7.24929 15.7509 6.69995 15.7509 6.01971C15.7509 5.33946 16.3003 4.79013 16.9805 4.79013C17.6608 4.79013 18.2101 5.33946 18.2101 6.01971C18.2103 6.18124 18.1786 6.34122 18.1169 6.49049C18.0552 6.63976 17.9646 6.77539 17.8504 6.8896C17.7362 7.00382 17.6006 7.09438 17.4513 7.1561C17.302 7.21782 17.142 7.24949 16.9805 7.24929Z"
        :fill="fill"
      />
    </svg>
    <svg
      v-if="type === 'fb'"
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.9465 1.23242H2.05361C1.59925 1.23242 1.23218 1.5995 1.23218 2.05385V20.9467C1.23218 21.4011 1.59925 21.7681 2.05361 21.7681H20.9465C21.4008 21.7681 21.7679 21.4011 21.7679 20.9467V2.05385C21.7679 1.5995 21.4008 1.23242 20.9465 1.23242ZM18.5746 7.22628H16.9343C15.6482 7.22628 15.3993 7.83722 15.3993 8.73566V10.7148H18.4693L18.0689 13.8131H15.3993V21.7681H12.1982V13.8157H9.52091V10.7148H12.1982V8.43019C12.1982 5.77852 13.818 4.33331 16.1847 4.33331C17.3193 4.33331 18.2922 4.41802 18.5772 4.45653V7.22628H18.5746Z"
        :fill="fill"
      />
    </svg>
  </div>
</template>
<script>
export default {
  props: {
    fill: {
      type: String,
      default: "white",
    },
    type: {
      type: String,
      default: "twitter",
    },
  },
};
</script>
