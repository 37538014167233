<template>
  <nav :class="classFor('nav')">
    <SideNav :class="{ '-left-full': !isNavOpen }" @close="isNavOpen = false" />
    <div
      class="container relative mx-auto flex justify-between items-center w-full py-6 md:px-16 px-4"
    >
      <div class="flex items-center">
        <div
          @click="openSideNav"
          class="flex flex-col items-center justify-center cursor-pointer md:hidden"
        >
          <div
            class="w-6 h-0.5"
            :class="isHome ? 'bg-cream-white' : 'bg-black'"
          ></div>
          <div
            class="w-6 h-0.5 my-1.5"
            :class="isHome ? 'bg-cream-white' : 'bg-black'"
          ></div>
          <div
            class="w-6 h-0.5"
            :class="isHome ? 'bg-cream-white' : 'bg-black'"
          ></div>
        </div>
        <div class="flex">
          <a
            href="/"
            class="tracking-wide font-bold hidden md:block md:mr-12 mr-6"
            :class="isHome ? 'text-cream-white' : 'text-black'"
            >Featured</a
          >
          <a
            href="/events"
            class="tracking-wide hidden md:block"
            :class="isHome ? 'text-cream-white' : 'text-black'"
            >Events</a
          >
        </div>
      </div>
      <a href="/">
        <img
          class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          src="@/assets/svg/saisse-face.svg"
          alt="Philippe Saisse Face"
        />
      </a>
      <div
        :class="classFor('wrapper')"
        class="flex items-center gap-1 font-extrabold uppercase md:text-lg text-base tracking-wider"
      >
        <template v-if="profileExists">
          <div class="relative" id="avatar">
            <div class="flex gap-4">
              <a
                class="cursor-pointer flex items-center gap-x-2"
                @click="isDropDownOpen = !isDropDownOpen"
              >
                <span class="md:block hidden" v-if="session.name">{{
                  session.name
                }}</span>
                <SvgUser :color="accentColor" />
              </a>
            </div>
            <div
              v-if="isDropDownOpen"
              id="dropdown"
              class="absolute top-12 right-0 z-10 w-44 bg-dark-cream rounded divide-y divide-gray-100 shadow-lg"
            >
              <ul
                class="py-1 text-sm text-white"
                aria-labelledby="dropdownDefault"
              >
                <li>
                  <a
                    href="#"
                    class="md:hidden block py-2 px-4 dark:hover:bg-cream-white dark:hover:text-black"
                    >{{ session.name }}</a
                  >
                </li>
                <li>
                  <RouterLink
                    to="/account"
                    class="block py-2 px-4 dark:hover:bg-cream-white dark:hover:text-black"
                    >Profile</RouterLink
                  >
                </li>
                <li>
                  <a
                    @click="logout"
                    class="cursor-pointer block py-2 px-4 dark:hover:bg-cream-white dark:hover:text-black"
                    >Sign Out</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="flex gap-4">
            <RouterLink
              :to="'/sign-in'"
              class="md:flex gap-1 items-center hidden"
            >
              <span :class="isHome ? 'text-cream-white' : 'text-black'"
                >Sign In</span
              >
              <SvgRightArrow class="md:w-auto w-5" :fill="classFor('arrow')" />
            </RouterLink>
            <RouterLink class="btn yellow small has-border" :to="'/sign-up'">
              <span>Sign Up</span>
            </RouterLink>
          </div>
        </template>
      </div>
    </div>
  </nav>
</template>
<script>
import SvgUser from "@/components/svg/User.vue";
import SvgRightArrow from "@/components/svg/RightArrow.vue";
import { useSessionStore } from "@/stores/session";
import SideNav from "@/components/SideNav.vue";
export default {
  components: {
    SvgUser,
    SvgRightArrow,
    SideNav,
  },
  setup() {
    const session = useSessionStore();
    return { session };
  },
  data() {
    return {
      isNavOpen: false,
      isDropDownOpen: false,
    };
  },
  computed: {
    profileExists() {
      return this.session.profileId;
    },
    sessionExists() {
      return this.session.sessionId !== false;
    },
    isHome() {
      return this.$route.path === "/";
    },
    accentColor() {
      return this.isHome ? "#FFD600" : "#A29887";
    },
  },
  methods: {
    openSideNav() {
      this.isNavOpen = true;
    },
    logout() {
      this.session.abandonSession();
      this.$router.push({ path: "/" }).then(() => {
        this.$router.go();
      });
    },
    classFor(scope) {
      switch (scope) {
        case "nav":
          return this.isHome
            ? "absolute top-0 right-0 left-0 w-full"
            : "border-b-2 border-dark-cream border-opacity-40";
        case "wrapper":
          return this.isHome
            ? "text-p-yellow hover:text-dark-yellow"
            : "text-dark-cream";
        case "arrow":
          return this.isHome ? "#E9DECC" : "#000000";
        default:
          return "";
      }
    },
  },
  mounted() {
    document.addEventListener("click", (e) => {
      if (!this.$el.contains(e.target)) {
        if (this.isDropDownOpen) {
          this.isDropDownOpen = false;
        }
      }
    });
  },
};
</script>
