<template>
  <section
    class="lg:w-3/5 container mx-auto md:p-0 px-4 md:my-32 my-8 relative"
  >
    <div class="flex justify-start items-center gap-1 mb-8">
      <h1 class="mr-2">Account</h1>
      <SvgProfile />
    </div>

    <div class="flex flex-col gap-5">
      <div class="bg-washed-white border-2 border-p-black md:p-6 p-4">
        <h4 class="md:text-2xl text-xl md:mb-6 mb-4">Profile Information</h4>
        <div class="details grid md:grid-cols-2 grid-cols-1 justify-between">
          <div class="flex flex-col gap-2">
            <p v-if="profile" class="font-semibold">
              {{ profile.name }}
            </p>
            <div
              v-else
              class="w-9/12 h-2 bg-gray-300 rounded animate-pulse"
            ></div>

            <p class="font-semibold text-light-gray" v-if="profile">
              {{ this.profile.email }}
            </p>
            <div
              v-else
              class="w-6/12 h-2 bg-gray-300 rounded animate-pulse mt-2"
            ></div>

            <p class="text-p-black opacity-60" v-if="profile">
              Phone: {{ this.profile.tel }}
            </p>
            <div
              v-else
              class="w-6/12 h-2 bg-gray-300 rounded animate-pulse mt-2"
            ></div>
          </div>
          <div class="md:pl-32 md:mt-0 mt-6">
            <template v-if="isStripeCustomer === true">
              <a @click="createPortalSession" class="cursor-pointer">
                <div
                  class="flex justify-between border-b-2 md:border-t-0 border-p-black py-3.5"
                >
                  <p class="uppercase font-bold tracking-wider">Billing</p>
                  <SvgRightArrow fill="#101010" />
                </div>
              </a>
            </template>
            <template v-if="isStripeCustomer === false">
              <a @click="createCheckoutSession" class="cursor-pointer">
                <div
                  class="flex justify-between border-b-2 md:border-t-0 border-p-black py-3.5"
                >
                  <p class="uppercase font-bold tracking-wider">
                    Finish Subscription Sign Up
                  </p>
                  <SvgRightArrow fill="#101010" />
                </div>
              </a>
            </template>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import SvgRightArrow from "@/components/svg/RightArrow.vue";
import {
  fetchProfile,
  createStripePortalSession,
  createStripeCheckoutSession,
} from "@/services/SessionService";
import { useSessionStore } from "@/stores/session";
export default {
  components: {
    SvgRightArrow,
  },
  setup() {
    const session = useSessionStore();
    return { session };
  },
  data() {
    return {
      subscriptions: null,
      subscriptionId: null,
      loading: false,
      profile: null,
    };
  },
  computed: {
    isStripeCustomer() {
      return this.profile?.payment_info?.is_stripe_customer;
    },
  },
  methods: {
    async fetchProfile() {
      // if (profileId) {
      await fetchProfile().then(
        (res) => {
          console.log(res);
          this.profile = res.profile;
          this.session.name = this.profile.name;
        },
        ({ status }) => {
          if (status === 401) {
            this.session.abandonSession();
          }
        }
      );
      // }
    },
    async createPortalSession({ customer, return_url }) {
      await createStripePortalSession({ customer, return_url }).then(
        (res) => (window.location.href = res.data.url)
      );
    },
    async createCheckoutSession() {
      await createStripeCheckoutSession().then((res) => {
        window.location.href = res.data.url;
        return res;
      });
    },
  },
  async mounted() {
    document.body.classList.add("bg-cream-white");
    document.body.classList.remove("bg-p-black");

    await this.fetchProfile();
  },
};
</script>
