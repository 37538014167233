<template>
  <div
    class="fixed left-0 top-0 bottom-0 w-3/4 border-l-2 border-black bg-cream-white z-50 transition-all duration-500"
  >
    <div class="h-full p-6">
      <div @click="$emit('close', true)" class="flex w-full justify-end mb-6">
        <img src="@/assets/svg/close.svg" class="w-6" alt="Close Button" />
      </div>
      <div class="flex flex-col justify-between h-full">
        <div>
          <div class="flex flex-col">
            <a href="/events" class="mb-2">Featured</a>
            <a href="/events">Events</a>
          </div>
          <div class="grid grid-cols-2 gap-3 mt-6">
            <a
              :href="'/sign-in'"
              class="btn text-center cream-white small has-border"
            >
              <span>Sign In</span>
            </a>
            <a
              class="btn yellow small text-center has-border"
              :href="'/sign-up'"
            >
              <span>Sign Up</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
