import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import PrivacyPolicyView from "@/views/PrivacyPolicyView.vue";
import TermsOfServiceView from "@/views/TermsOfServiceView.vue";
import CodeView from "@/views/CodeView.vue";
import Account from "@/views/AccountView.vue";
import CreateProfileView from "@/views/CreateProfileView.vue";
import SignInView from "@/views/SignInView.vue";
import SignUpView from "@/views/SignUpView.vue";
import ShowsView from "@/views/ShowsView.vue";
import NotFoundView from "@/views/NotFoundView.vue";

import session from "@/middleware/session";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "home",
      component: HomeView,
      meta: {
        title:
          "Discover exclusive content on the Philippe Saisse app. Sign in or sign up today.",
      },
    },
    {
      path: "/privacy",
      name: "privacy",
      component: PrivacyPolicyView,
      meta: {
        title: "Read through our Privacy Policy for the Philippe Saisse app.",
      },
    },
    {
      path: "/terms-of-service",
      name: "tos",
      component: TermsOfServiceView,
      meta: {
        title: "Read through our Terms of Service for the Philippe Saisse app.",
      },
    },
    {
      path: "/code",
      name: "code",
      component: CodeView,
      meta: {
        title:
          "Secure your account with a one-time verification code sent to your phone.",
      },
    },
    {
      path: "/account",
      name: "account",
      component: Account,
      meta: {
        title: "Your Account",
        middleware: session,
      },
    },
    {
      path: "/start-membership",
      name: "start-membership",
      component: CreateProfileView,
      meta: {
        title:
          "Create your Philippe Saisse profile by entering in your information.",
      },
    },
    {
      path: "/sign-in",
      name: "sign-in",
      component: SignInView,
      meta: {
        title:
          "Existing users sign in to your Philippe Saisse account on the Saisse Media website.",
      },
    },
    {
      path: "/sign-up",
      name: "sign-up",
      component: SignUpView,
      meta: {
        title:
          "Start creating your Philippe Saisse account on the Saisse Media website.",
      },
    },
    {
      path: "/events",
      name: "events",
      component: ShowsView,
      meta: {
        title: "Browse through Philippe’s past and upcoming events.",
      },
    },
    {
      path: "/:pathMatch(.*)*",
      name: "not-found",
      component: NotFoundView,
    },
  ],
  scrollBehavior() {
    document.getElementById("app").scrollIntoView();
  },
});

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`;
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});

export default router;
