import { useSessionStore } from "@/stores/session";
export default function auth({ next, router }) {
  const session = useSessionStore();
  if (!session.profileId) {
    session.abandonSession();
    return router.push({ name: "home" });
  }

  return next();
}
