<template>
  <section
    class="privacy-policy md:mt-24 mt-16 md:w-6/12 lg:w-2/3 container mx-auto md:p-0 px-4 relative"
  >
    <h1>Privacy Policy</h1>
    <p>Last updated: August 30, 2022</p>
    <p>
      Saisse Media, LLC, a subsidiary of Quevis, LLC (individually and
      collectively “We,” “Us,” or “Our”) is pleased to provide you with access
      to our Services. This Privacy Policy describes our practices with respect
      to the collection, transfer, manipulation, disclosure and other uses of
      Your Information and certain other information collected by us through our
      Services. For purposes of this Privacy Policy, “Your Information” means
      information about you which may be of a confidential nature and may
      include personally identifiable information, including your first and last
      name and email address. Capitalized terms used but not defined in this
      Notice have the meanings ascribed to them by our Terms of Service (our
      “Terms”).
    </p>
    <p>
      This Privacy Policy applies to information collected by us through our
      Services, but does not apply to information collected by any person or
      entity other than us, even if related to our Services, and/or by our
      third-party service providers).
    </p>
    <p>
      PLEASE READ THIS PRIVACY POLICY CAREFULLY BEFORE USING OUR SERVICES. BY
      USING OUR SERVICES OR BY CLICKING TO ACCEPT OR AGREE TO OUR TERMS WHEN
      THIS OPTION IS MADE AVAILABLE TO YOU, YOU ACCEPT AND AGREE TO BE BOUND AND
      ABIDE BY OUR TERMS, INCLUDING THIS PRIVACY POLICY. IF YOU DO NOT WANT YOUR
      INFORMATION USED OR DISCLOSED IN THE MANNER DESCRIBED IN THIS PRIVACY
      POLICY, OR IF YOU DO NOT AGREE WITH ANY TERM OR CONDITION IN OUR TERMS,
      INCLUDING THIS PRIVACY POLICY, THEN YOU MUST NOT ACCESS OR USE OUR
      SERVICES (IN WHOLE OR IN PART) OR SUBMIT YOUR INFORMATION TO US.
    </p>
    <p>
      This Privacy Policy may change from time to time. If we make changes, we
      will notify you by revising the date at the top of this Privacy Policy.
      Amendments will take effect immediately on our posting of the updated
      Privacy Policy. Your continued access or use of our Services after
      receiving the notice of changes means you accept the updated Privacy
      Policy, so please check this policy periodically for updates.
    </p>
    <h4>1.&nbsp; Children Under the Age of 18</h4>
    <p>
      Our Services are intended to be accessed and used only by adults and are
      not directed to minors. If you are below the age of 18, you may access and
      participate in our Services only under the supervision of a parent or
      legal guardian who agrees to be bound by this Privacy Policy and our
      Terms.
    </p>
    <p>
      We do not knowingly collect personally identifiable information by anyone
      under the age of 16 and you should not provide us with any information
      regarding any individual under the age of 16. &nbsp;If we learn that we
      have inadvertently gathered information from anyone under the age of 16,
      we will take reasonable measures to promptly remove that information from
      our records.
    </p>
    <h4>2.&nbsp; What Information We Collect and How We Collect It</h4>
    <p>
      We receive and collect several types of information about you, such as the
      information described below.
    </p>
    <p>
      <strong>Information you provide to us:</strong> From time to time, you may
      provide us with certain information, including Your Information, such as
      your name and email address. Instances where you may provide this
      information include when you sign up for an account on our Services, fill
      out forms or fields on or through our Services, or sign up for any
      promotions or to receive newsletters or other materials from or about
      us.&nbsp;
    </p>
    <p>
      <strong>Information we receive from third parties:</strong>&nbsp; We may
      obtain information about you from sources other than you, such as from our
      payment processing services provider when you make payments for a
      subscription to our Services, or for other transactions facilitated on or
      through our Services. Any content or information submitted by you to third
      parties will be used and disclosed by such third parties in accordance
      with their respective privacy policies. You should review the privacy
      policies and practices of such third parties prior to disclosing
      information to them.
    </p>
    <p>
      We may also collect information on third party platforms such as HubSpot,
      Google AdWords, Google Analytics, etc.&nbsp; Additionally, some
      information we collect is publicly available. For example, we may collect
      information you submit to a blog, a chat room, or a social network like
      Facebook, Twitter or Google+.
    </p>
    <p>
      <strong>Information we collect automatically:</strong> As you navigate
      through and interact with our Services, we and third parties acting on our
      behalf may use automatic data collection technologies to collect certain
      information. See Section 3 “Cookies and Other Automatic Tracking
      Technologies” for more information.
    </p>
    <h4>
      Our Collection Practices with Respect to Certain Specific Information:
    </h4>
    <p>
      <em>Device Information</em>. When you access or use our Services through
      your computer, mobile phone or other device, we may collect information
      regarding and related to your device, such as hardware models and IDs,
      device type, operating system version, the request type, the content of
      your request and basic usage information about your use of our Services,
      such as date and time. In addition, we may collect information regarding
      application-level events, such as crashes, and associate that temporarily
      with your account (if any) to provide customer service. We may also
      collect and store information locally on your device using mechanisms such
      as browser web storage and application data caches.
    </p>
    <p>
      <em>Location Information</em>. If you submit your zip code or other
      geographic information to us, you may provide us with your location
      information. Furthermore, if you enable the collection of location
      information on your device, then we may collect information about your
      actual location, such as your mobile device’s GPS coordinates. We may also
      use other technologies to determine your location (such as through the
      location of nearby Wi-Fi access points or cell towers) or obtain your
      location information from third parties.
    </p>
    <p>
      &zwj;<em>Communications with Us</em>. We may collect information about
      your communications with us, including relating to support questions, your
      account, and other inquiries.
    </p>
    <p>
      <strong>Third-party content providers may collect information:</strong>
      Some content or applications, including advertisements, on our Services
      may be served by third parties, including our third-party service
      providers, ad networks and servers, content providers and other
      application providers. These third parties may collect information about
      you when you use our Services using cookies alone or in conjunction with
      web beacons or other tracking technologies. We do not control these third
      parties' tracking technologies or how they may be used. If you have any
      questions about an advertisement or other targeted content, you should
      contact the responsible provider directly. Please note that the
      information collected by these third parties may be associated with Your
      Information and that these third parties may collect information,
      including Your Information, about your online activities over time and
      across different websites and other online services.&nbsp;
    </p>
    <h4>3. Cookies and Other Automatic Tracking Technologies</h4>
    <p>
      Description of the Technologies Utilized on our Services<em>:</em> We may
      use various technologies to collect and store information when you use our
      Services, including sending cookies, embedded scripts, web beacons, pixel
      tags or other anonymous identifiers to your device, or otherwise tracking
      your activities on our Services over time.
    </p>
    <ul role="list">
      <li>
        “Cookies” are small web files that a site or its provider transfers to
        your device’s hard drive through your web browser that enables the
        site’s or provider’s system to recognize your browser and remember
        certain information. The length of time a cookie will stay on your
        browsing device depends on whether it is a “persistent” or “session”
        cookie. Session cookies will only stay on your device until you stop
        browsing. Persistent cookies stay on your browsing device until they
        expire or are deleted. A “web beacon” is a type of technology that lets
        us know if you visited a certain page or whether you opened an email. A
        “pixel tag” is a type of technology placed within a website or e-mail
        for the purpose of tracking activity, which is often used in combination
        with cookies. Other “anonymous identifiers” include random strings of
        characters used for the same purposes as cookies, such as with mobile
        devices where cookie technology may not be available. These technologies
        help us know that you are logged on to or using our Services, provide
        you with features based on your preferences, help us understand when and
        how you are interacting with our Services and compile other information
        regarding your use of our Services.
      </li>
      <li>
        With respect to Cookies, we use first-party and third-party cookies to:
        make our Services function properly; improve our Services; make access
        to our Services easier; recognize you when you return to our Services;
        track your interaction with our Services; enhance your experience with
        our Services; remember information you have already provided; and to
        provide a secure browsing experience during your use of our Services.
        The categories of cookies used or that may be used on our Services
        include:
      </li>
      <li>
        Strictly Necessary Cookies, which are needed for our platform to operate
        as you reasonably expect.&nbsp;
      </li>
      <li>
        Functional or Preference Cookies, which are used to remember your name
        or choices.
      </li>
      <li>
        Performance or Analytic Cookies, which collect passive information about
        your use of our platform.
      </li>
      <li>
        Advertising or Targeting Cookies, which are used to make advertising
        messages more relevant and personalized to you based on your inferred
        interests.
      </li>
      <li>
        We may use third-party service providers to monitor and analyze the use
        of our Services. For example, we may use Google Analytics as one of our
        analytics service providers that tracks and reports platform (including
        website) traffic. For more information on the privacy practices of
        Google, please visit the Google Privacy &amp; Terms
        <a href="https://policies.google.com/privacy">web page</a>. To learn
        more about how Google collects and uses data
        <a href="https://policies.google.com/technologies/partner-sites">here</a
        >. To opt out of Google Analytics Advertising Features please use
        <a href="https://adssettings.google.com/">Google Ad Settings</a>. To opt
        out of Google Analytics entirely please use
        <a href="https://tools.google.com/dlpage/gaoptout">this link</a>.
      </li>
    </ul>
    <p>
      The information collected through these technologies, standing alone,
      cannot be used to determine your identity. Such information may, however,
      be combined in a way that makes it become personally identifiable
      information (i.e., information that can identify you). For example, we may
      tie this information to personal information about you that we collect
      from other sources or that you provide to us. If this happens, we will
      treat the combined information as personally identifiable information.
    </p>
    <p>
      Choices as to Cookies and Other Automatic Tracking Technologies: It may be
      possible to disable some (but not all) Cookies or automatic data
      collection technologies through your device or browser settings, but doing
      so may affect the functionality of all or a portion of our Services. The
      method for disabling Cookies or other automatic collection technologies
      may vary by device and browser but can usually be found in preferences or
      security settings. For example, iOS and Android devices each have settings
      which are designed to limit forms of ad tracking. See Section 7 “Choices
      About How We Use and Disclose Your Information” for more information.
    </p>
    <p>
      Do Not Track Requests:&nbsp; Some Internet browsers, such as Internet
      Explorer, Firefox, and Safari, include the ability to transmit “Do Not
      Track” or “DNT” signals. Since uniform standards for “DNT" signals have
      not been adopted, our Services do not currently process or respond to
      “DNT” signals.
    </p>
    <h4>4.&nbsp; How We Use Your Information</h4>
    <p>
      We may use the information we collect for various lawful business
      purposes. Among others, these purposes may include using this information,
      including Your Information, to:
    </p>
    <ul role="list">
      <li>
        <em>Present our Services and related content to you</em>. For example,
        to provide our Services, including its features and services to you
        and/or other account owners, and to customize Services features and
        recommendations for accounts users. We may also use Your Information to
        manage our relationship and contracts with account owners, including
        billing, compliance with contractual obligations, and related
        administration.<em>&zwj;</em>
      </li>
      <li>
        <em>Contact you and provide you with information</em>. For example, if
        you subscribe to an artist account on or through our Services, we may
        contact you to notify you of and/or deliver applicable content to which
        you are subscribed. Additionally, if you complete the “contact us” form
        on our website, we might contact you via e-mail or other communications
        to provide you with information regarding our Services, promotions,
        offers, personalized information and other information from us or our
        third-party service providers or business partners or to respond to
        comments, inquiries, and other information submitted by you.<em
          >&zwj;</em
        >
      </li>
      <li>
        <em>Provide you with customer support</em>. For example, if you request
        us to contact you in connection with any customer support matters, we
        may collect certain information from you in order to assist you as
        requested.&nbsp;
        <em>&zwj;</em>
      </li>
      <li>
        <em
          >Analyze, improve and manage our Services, products, service offerings
          and operations.</em
        >
        For example, we might obtain feedback regarding our Services, products,
        and service offerings, understand and anticipate your needs and
        preferences, better understand your use of our Services or products or
        service offerings, customize and tailor content and advertising, improve
        our marketing and promotional efforts, engage in statistical analysis
        and provide feedback or information to our business partners, vendors,
        advertisers and other third parties.&nbsp;<strong
          ><em>&zwj;</em></strong
        >
      </li>
      <li>
        Resolve problems and disputes, and engage in other legal and security
        matters. For example, we may use the information we collect to comply
        with, monitor compliance with and enforce this Privacy Policy, our Terms
        (including any terms and conditions incorporated or referenced in our
        Terms) and any other applicable agreements and policies, meet other
        legal and regulatory requirements, and protect the security and
        integrity of our Services. We might also use Your Information to
        authenticate accounts and activity, detect, investigate, and to prevent
        malicious conduct or unsafe experiences or address security threats,
        protect public safety. We might also use information that we collect to
        investigate any compliant you make regarding our Services, content,
        products, service offerings or other related matters, to provide
        evidence in any dispute or anticipated dispute between you and us, or to
        prevent fraud or other criminal or otherwise inappropriate
        activities.<em>&zwj;</em>
      </li>
      <li>
        <em>
          Comply with a request from you in connection with the exercise of your
          rights. For example, where you have asked us not to contact you for
          marketing purposes, we or our service providers will keep a record of
          this on our suppression lists to be able to comply with your request.
        </em>
      </li>
      <li>
        <em
          >Other Purposes. We may also use your information in other ways. To
          the extent required by applicable law, we will provide notice at the
          time of collection and obtain your consent.</em
        >&zwj;
      </li>
    </ul>
    <h4>5.&nbsp; How We Disclose Your Information</h4>
    <p>
      General Disclosure of Your Information:&nbsp; We may disclose the
      information we collect, including Your Information:
    </p>
    <ul role="list">
      <li>
        if you subscribe to an artist’s account on our Services, to such artist
        and his/her/its representatives,
      </li>
      <li>
        to our third-party vendors or suppliers (including our payment
        processing Third-Party Provider and our hosting provider) so that they
        may provide support for our internal and business operations, including
        for the processing of your payments for a subscription to our Services,
        data verification, data storage, surveys, research, internal marketing,
        delivery of promotional, marketing and transaction materials and our
        Services’ maintenance and security. For example, but not by limitation,
        we may use (and thus disclose certain of Your Information to):
      </li>
      <li>
        Google Analytics so that they can help us understand how our customers
        use our website and platform – you can read more about how Google uses
        your Personal Information here:
        <a href="https://www.google.com/intl/en/policies/privacy/"
          >https://www.google.com/intl/en/policies/privacy/</a
        >. You can also opt-out of Google Analytics here:
        <a href="https://tools.google.com/dlpage/gaoptout"
          >https://tools.google.com/dlpage/gaoptout</a
        >.&nbsp;
      </li>
      <li>
        HubStop in order to effectuate our marketing campaigns — you can read
        more about how HubSpot uses Your Information here:
        <a
          href="https://legal.hubspot.com/privacy-policy?__hstc=185383542.942ba2c5abf8f42a36562c2113cb7ee2.1582556735370.1583519879552.1583752260330.4&amp;__hssc=185383542.2.1583752260330&amp;__hsfp=2293565776"
        >
          https://legal.hubspot.com/privacy-policy
        </a>
        .&nbsp;
      </li>
      <li>
        to our affiliates and their respective employees, agents and
        representatives involved in delivering our Services, products and other
        services to you;&nbsp;
      </li>
      <li>to fulfill the purpose for which you provide it;</li>
      <li>
        to any person who, in our reasonable judgment, is authorized to receive
        Your Information as your agent, including as a result of your business
        dealings with that person (for example, your attorney);
      </li>
      <li>
        to third parties when you engage in certain activities through our
        Services, such as those financial or other institutions with which you
        maintain accounts to which you have authorized us to access for purposes
        of providing our Services to you;
      </li>
      <li>
        as required by applicable law or ordered by a court, regulatory or
        administrative agency;
      </li>
      <li>
        as we deem necessary, in our sole discretion, if we believe that you are
        violating any applicable law, rule, restriction or regulation, or are
        otherwise interfering with another’s rights or property, including our
        rights or property;
      </li>
      <li>
        if requested by authorities in the event of any act instance of local,
        regional or national emergency;
      </li>
      <li>
        to enforce our Terms, including this Privacy Policy, and any other
        applicable agreements and policies;
      </li>
      <li>with your consent;</li>
      <li>to otherwise enforce or protect our rights; and</li>
      <li>
        in connection with a Business Transfer (see <em>“</em>Other Disclosures
        by Us<em>” </em>below).
      </li>
    </ul>
    <p>
      <strong>
        Please note that the list above is not exhaustive and that we may, to
        the extent permitted by applicable law, disclose the information we
        collect to third parties at any time, for any lawful purpose, without
        notice or compensation to you
      </strong>
      .
    </p>
    <p>
      <strong>
        When we disclose the information we collect to third parties, such
        information will become permanently subject to the information use and
        sharing practices of the third party, and the third party will not be
        restricted by this Privacy Policy with respect to its use and further
        sharing of such information. Furthermore, these third parties may
        further disclose, share and use this information. By submitting
        information (including Your Information) to us, you expressly consent to
        such disclosure and use of such information. If you do not want this
        information (including Your Information) shared as described above, then
        you should not provide us with such information.
      </strong>
    </p>
    <p>
      <strong>
        WITHOUT LIMITING THE GENERALITY OF THE FOREGONG, IF YOU DO NOT WANT YOUR
        INFORMATION SHARED WITH AN ARTIST (OR HIS OR HER REPRESENTATIVES) TO
        WHOSE ACCOUNT YOU HAVE SUBSCRIBED ON OUR SERVICES, THEN YOU SHOULD NOT
        SUBSCRIBE TO SUCH ARTIST’S ACCOUNT. ONCE WE DISCLOSE YOUR INFORMATION TO
        ANY SUCH ARTIST, HE OR SHE AND HIS OR HER REPRESENTATIVE’S MAY FURTHER
        USE, DISCLOSE AND SHARE THIS INFORMATION SUBJECT ONLY TO COMPLIANCE WITH
        APPLICABLE LAWS. PLEASE NOTE: WHILE WE CONTRACTUALLY PROHIBIT USE OF ANY
        SUCH INFORMATION BY ARTISTS IN VIOLATION OF APPLICABLE LAW, WE CANNOT
        OTHERWISE CONTROL ANY ARTIST AND AS A RESULT WILL NOT BE LIABLE, AND
        HEREY EXPRESSLY DISCLAIM ANY SUCH LIABILITY, THAT MAY ARIASE OUT OF OR
        RELATE TO AN ARTIST’S USE OF YOUR INFORMATION.
      </strong>
    </p>
    <p><strong>&zwj;</strong>Other Disclosures by Us:</p>
    <p>
      <em>Aggregation and De-Personalization</em>. We may use and disclose any
      de-identified information for any lawful purpose, including for any
      commercial purpose. De-identified Information means information that does
      not identify you, including any identifiable information de-identified by
      either by combining it with information about others, for example, by
      aggregating your information with information about other persons, or by
      removing characteristics, such as your name, that make the information
      personally identifiable to you.
    </p>
    <p>
      &zwj;<em>Business Transfers</em>. We may disclose, lease, sell, assign or
      transfer the information we collect (including Your Information) to third
      parties in connection with a Business Transfer. A “Business Transfer”
      means a sale, acquisition, merger, consolidation, reorganization,
      bankruptcy or other corporate change involving us, even if the Business
      Transfer is only being contemplated and regardless of whether the Business
      Transfer relates to a part of or our whole business. Nothing in this
      Privacy Policy is intended to interfere with our ability to transfer all
      or part of our business, equity or assets (including our Services) to an
      affiliate or independent third party at any time, for any lawful purpose,
      without notice or compensation to you.
    </p>
    <p>
      Circumvention of Security Measures: We use a certain technical,
      administrative and organizational security measures to keep Your
      Information safe. However, despite our efforts, please be aware that
      methods of transmission and electronic storage are not completely secure.
      We cannot guarantee the privacy or security of Your Information, as third
      parties may unlawfully intercept or access transmissions or electronic
      storage. Further, to the extent permitted by law, we are not responsible
      for circumvention of any privacy settings or security measures of our
      Services. Therefore, you use our Services at your own risk, you should not
      expect that Your Information will always remain private, and we do not
      guarantee the performance or adequacy of our security measures. In the
      event that an unauthorized third party compromises our security measures,
      to the extent permitted by law, we will not be responsible for any
      damages, directly or indirectly, caused by an unauthorized third party’s
      ability to view, use or disseminate Your Information.
    </p>
    <h4>6.&nbsp; Choices About How We Use and Disclose Your Information</h4>
    <p>
      We strive to provide you with choices regarding our collection, use and
      disclosure of the information that we collect. Below are some mechanisms
      that provide you with control over such collection, use or disclosure:
    </p>
    <ul role="list">
      <li>
        <em>Cookies and Other Tracking Technologies</em>. It may be possible to
        disable some (but not all) Cookies or automatic data collection
        technologies through your device or browser settings, but doing so may
        affect the functionality of our Services. The method for disabling
        Cookies or other automatic collection technologies may vary by device
        and browser, but can usually be found in preferences or security
        settings. You can exercise your preferences in relation to cookies
        served on our Services by taking the steps outlined below.
      </li>
      <li>
        First-Party Cookies. You can use the browser with which you are viewing
        our Services to enable, disable or delete Cookies. To do this, follow
        the instructions provided by your browser (usually located within the
        “Help”, “Tools” or “Edit” settings). Please note, if you set your
        browser to disable Cookies, you may not be able to access secure areas
        of our Services. Also, if you disable certain Cookies, other parts of
        our Services may not work properly. You can find more information about
        how to change your browser cookie settings at
        <a href="https://www.allaboutcookies.org/">allaboutcookies.org</a>.
      </li>
      <li>
        Third-Party Cookies. To opt-out of third-party advertising networks and
        similar entities that use advertising cookies go to
        <a href="https://optout.aboutads.info/?c=2&amp;lang=EN"
          >aboutads.info/choices</a
        >.
      </li>
      <li>
        We do not control third-parties’ collection or use of your information
        to serve interest-based advertising. However, these third-parties may
        provide you with ways to choose not to have your information collected
        or used in this way. In addition, most web browsers provide help pages
        relating to setting Cookie preferences. More information may be found
        for the following browsers here:
      </li>
    </ul>
    <ul role="list">
      <li class="hack20-sub-bullet">
        <a href="https://support.google.com/chrome/answer/95647?hl=en-GB"
          >Google Chrome</a
        >
      </li>
      <li class="hack20-sub-bullet">
        <a
          href="https://support.apple.com/en-us/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
          >Safari (Desktop)</a
        >
      </li>
      <li class="hack20-sub-bullet">
        <a href="https://support.microsoft.com/en-us/kb/260971"
          >Internet Explorer</a
        >
      </li>
      <li class="hack20-sub-bullet">
        <a
          href="https://support.google.com/ics/nexus/bin/answer.py?hl=en&amp;answer=2425067"
          >Android Browser</a
        >
      </li>
      <li class="hack20-sub-bullet">
        <a
          href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer"
          >Mozilla Firefox</a
        >
      </li>
      <li class="hack20-sub-bullet">
        <a href="https://www.opera.com/help">Opera</a><br />
      </li>
    </ul>
    <ul role="list">
      <li>
        <em>E-mail Offers.</em> If you do not wish to receive e-mail offers or
        other information or communications from us, you can opt-out of
        receiving such e-mail offers or other information or communications from
        us (other than e-mails or other information or communications related to
        the status of your job application with us (if any), correction of user
        data, change of password and other similar communications essential to
        your transactions on or through our Services) by using the unsubscribe
        process at the bottom of the e-mail. Please be aware that it can take up
        to 10 business days to remove you from our marketing e-mail lists. If
        you opt-out from receiving our marketing e-mails, you may continue to
        receive certain status e-mails relating to your transactions on or
        through our Services.
      </li>
      <li>
        &zwj;<em>Managing Your Information</em>. If you create an account with
        us, then you can review and update Your Information by logging into that
        portion of our Services, visiting your account profile page and making
        changes.
      </li>
      <li>
        &zwj;<em>Online Behavioral Advertising</em>. As described above, we may
        use Your Information to provide you with targeted advertisements or
        marketing communications we believe may be of interest to you. Certain
        third-party advertising networks that deliver behavioral advertising are
        members of the Network Advertising Initiative (“NAI”). You can prevent
        NAI member companies from collecting preference data about you by
        visiting
        <a href="http://www.networkadvertising.org/choices/"
          >http://www.networkadvertising.org/choices/</a
        >
        and following the NAI’s directions. Note that if you opt-out through the
        NAI, you will still receive advertising. In addition, if you opt-out
        through the NAI and later delete your cookies, use a different browser
        or buy a new computer, you will need to renew your opt-out choice.
        Additional options for opting out of target advertising include, without
        limitation:
      </li>
      <li class="hack20-sub-bullet">
        FACEBOOK -
        <a href="https://www.facebook.com/settings/?tab=ads"
          >https://www.facebook.com/settings/?tab=ads</a
        >&nbsp;&nbsp;&nbsp;
      </li>
      <li class="hack20-sub-bullet">
        TWITTER -
        <a
          href="https://help.twitter.com/en/safety-and-security/privacy-controls-for-tailored-ads"
          >https://help.twitter.com/en/safety-and-security/privacy-controls-for-tailored-ads</a
        >&nbsp;
      </li>
      <li class="hack20-sub-bullet">
        GOOGLE -
        <a href="https://www.google.com/settings/ads/anonymous"
          >https://www.google.com/settings/ads/anonymous</a
        >&nbsp;
      </li>
      <li class="hack20-sub-bullet">
        BING -
        <a
          href="https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads"
          >https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads</a
        >&nbsp;
      </li>
      <li class="hack20-sub-bullet">
        Digital Advertising Alliance -
        <a href="http://optout.aboutads.info/">http://optout.aboutads.info/</a>
      </li>
    </ul>
    <h4>7.&nbsp; Information that You Disclose to Third Parties</h4>
    <p>
      Our Services, including any of our newsletters or e-mail messages, may
      contain links or access to websites or platforms operated by third parties
      that are beyond our control. Links or access to third parties from our
      Services is not an endorsement by us of such third parties, or their
      respective websites, applications, products, platforms or practices. We
      are not responsible for the privacy policies, terms and conditions,
      practices or the content of such third parties. All information that you
      disclose to such third parties will be subject to the privacy policies and
      practices of such third parties. You should review the privacy policies
      and practices of such third parties prior to disclosing information to
      them. If you have any questions about how these third parties use your
      information, you should review their policies and contact them directly.
    </p>
    <h4>8.&nbsp; Operation of our Services in the United States</h4>
    <p>
      Our Services is operated in the United States. As a result, the
      information we collect (including Your Information) will be used and
      disclosed as described in this Privacy Policy in the United States, as
      well as in other countries if you access our Services outside of the
      United States. In some cases, the laws of countries other than the United
      States regarding our use and disclosure of your information may be less
      stringent than the laws of the United States.
    </p>
    <h4>9.&nbsp; Your State Privacy Rights</h4>
    <p>
      State consumer privacy laws may provide their residents with additional
      rights regarding our use of their personal information. We will comply
      with all such laws that apply to us and with respect to our collection,
      use and/or disclosure of Your Information. For example, see Section 12,
      “Privacy Notice for Nevada Residents” below.
    </p>
    <h4>10.&nbsp; Changes to Our Privacy Policy</h4>
    <p>
      We may amend this Privacy Policy at any time and from time to time.
      Amendments will take effect immediately upon us posting the updated
      Privacy Policy on our Services. Accordingly, you are encouraged to revisit
      this Privacy Policy from time to time in order to review any changes that
      we have made. The date on which this Privacy Policy was last updated will
      be noted immediately above this Privacy Policy.
    </p>
    <h4>11.&nbsp; Contacting Us</h4>
    <p>
      If you have any questions, suggestions or complaints regarding our use or
      disclosure of the information we collect from you or wish to contact us to
      make a request regarding such information (including Your Information),
      please contact us at legal@saissemedia.com.
    </p>
    <p>
      In contacting us, you must include the reference to our “Privacy Request”
      on the subject line and in the body of the message and include the email
      address or mailing address, as applicable, for us to send our response. We
      reserve the right not to respond to inquiries submitted other than to the
      address specified above.
    </p>
    <h4>12.&nbsp; Privacy Notice for Nevada Residents</h4>
    <p>
      This Section 12 supplements the information contained in this Privacy
      Policy, but applies solely to all consumers who reside in the State of
      Nevada. Nevada law permits our users who are Nevada consumers to request
      that their personal data (as defined under applicable Nevada law) not be
      sold, even if their personal data is not currently being sold. Requests
      may be sent to legal@saissemedia.com, and are free of charge.
    </p>
  </section>
</template>
<style scoped>
.privacy-policy p,
.privacy-policy ul,
.privacy-policy li {
  margin: 1.5rem 0;
}
</style>
<script>
import { useHead } from "@unhead/vue";
import { privacyMetaTags } from "../meta/metaData";
export default {
  setup() {
    useHead({
      meta: privacyMetaTags(),
    });
  },
  mounted() {
    document.body.classList.remove("bg-p-black");
    document.body.classList.add("bg-cream-white");
  },
};
</script>
