<template>
  <section
    class="md:mt-24 mt-16 md:w-6/12 lg:w-4/12 container mx-auto md:p-0 px-4 relative"
  >
    <form class="mb-6">
      <p class="font-semibold tracking-wider uppercase">Step 2</p>
      <h1 class="small my-4">Set up your profile</h1>
      <p class="body-text"></p>

      <!-- <div class="flex flex-col mt-4">
        <label class="font-semibold mb-1" for="email">Email</label>
        <input
          type="email"
          disabled
          v-model="form.email"
          placeholder="Email"
          class="w-full text-light-gray border-2 border-dark-cream p-4"
        />
      </div>

      <div class="flex flex-col mt-4">
        <label class="font-semibold mb-1" for="first_name">First Name</label>
        <input
          type="text"
          required
          v-model="form.first_name"
          placeholder="First Name"
          class="w-full text-black border-2 border-dark-cream p-4"
        />
      </div>

      <div class="flex flex-col mt-4">
        <label class="font-semibold mb-1" for="last_name">Last Name</label>
        <input
          type="text"
          required
          v-model="form.last_name"
          placeholder="Last Name"
          class="w-full text-black border-2 border-dark-cream p-4"
        />
      </div>

      <div class="flex flex-col mt-4">
        <label class="font-semibold mb-1" for="city">Phone Number</label>
        <input
          type="text"
          required
          v-model="form.tel"
          placeholder="e.g. +1 (405) 123 4567"
          class="w-full text-black border-2 border-dark-cream p-4"
        />
      </div> -->

      <p class="body-text text-p-black opacity-70 my-4">
        Begin your membership today and gain access to Philippe’s highlights,
        curated channels, and exclusive media content not available on any other
        platform.` `Enjoy Philippe’s personal collection of music, videos,
        behind the scenes action, 4K Live Shows, and much more!
      </p>

      <p class="body-text text-p-black opacity-70 my-4 md:mb-8">
        By clicking the “Start Membership” button below, you agree to our Terms
        of Use, Privacy Statement, that you are over 18, and that Quevis will
        automatically continue your membership and charge the membership fee
        (currently $5.00/month) to your payment method until you cancel. You may
        cancel at any time to avoid future charges. To cancel, go to Account and
        click “Cancel Membership”
      </p>

      <a
        :href="session.stripeCheckoutUrl"
        class="btn yellow has-border block text-center w-60"
      >
        <Spinner v-if="loading" />
        <span v-else>Start Membership</span>
      </a>
    </form>
  </section>
</template>
<script>
import Spinner from "@/components/svg/Spinner.vue";
import { enroll, createStripeCheckoutSession } from "@/services/SessionService";
import { useSessionStore } from "@/stores/session";
import { useToast } from "vue-toastification";
import { useHead } from "@unhead/vue";
import { createProfileMetaTags } from "../meta/metaData";
import { parsePhoneNumber } from "libphonenumber-js";
export default {
  components: {
    Spinner,
  },
  setup() {
    const session = useSessionStore();
    const toast = useToast();

    useHead({
      meta: createProfileMetaTags(),
    });
    return { session, toast };
  },
  data() {
    return {
      states: [
        "Alabama",
        "Alaska",
        "American Samoa",
        "Arizona",
        "Arkansas",
        "California",
        "Colorado",
        "Connecticut",
        "Delaware",
        "District of Columbia",
        "Federated States of Micronesia",
        "Florida",
        "Georgia",
        "Guam",
        "Hawaii",
        "Idaho",
        "Illinois",
        "Indiana",
        "Iowa",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Maine",
        "Marshall Islands",
        "Maryland",
        "Massachusetts",
        "Michigan",
        "Minnesota",
        "Mississippi",
        "Missouri",
        "Montana",
        "Nebraska",
        "Nevada",
        "New Hampshire",
        "New Jersey",
        "New Mexico",
        "New York",
        "North Carolina",
        "North Dakota",
        "Northern Mariana Islands",
        "Ohio",
        "Oklahoma",
        "Oregon",
        "Palau",
        "Pennsylvania",
        "Puerto Rico",
        "Rhode Island",
        "South Carolina",
        "South Dakota",
        "Tennessee",
        "Texas",
        "Utah",
        "Vermont",
        "Virgin Island",
        "Virginia",
        "Washington",
        "West Virginia",
        "Wisconsin",
        "Wyoming",
      ],
      form: {
        email: "",
        first_name: "",
        last_name: "",
        card_number: "",
        expiration_date: "",
        cvv: "",
        tel: "",
        address: {
          line1: "",
          line2: "",
          city: "",
          state: "",
          postal_code: "",
          country: "US",
        },
      },
      loading: false,
    };
  },
  methods: {
    isProfileValid(profileId) {
      return profileId && profileId !== "undefined";
    },
    validPhoneForE164(phoneNumber) {
      const regEx = /^\+[1-9]\d{10,14}$/;
      return regEx.test(phoneNumber);
    },
    async submitForm() {
      this.loading = true;

      const parsedPhoneNumber = parsePhoneNumber(this.form.tel, "US");

      if (parsedPhoneNumber.isPossible() === false) {
        this.toast.error("Make sure you're using a valid phone number.");
        this.loading = false;
        return;
      }

      const profile = {
        name: this.fullName,
        email: this.form.email,
        namespace: "psaisse",
        tel: parsedPhoneNumber.format("E.164"),
        addr: "",
        stripe_customer_id: "",
        stripe_payment_method_id: "",
      };

      // if (
      //   this.validPhoneForE164(`+1${profile.tel.replace(/\s/g, "")}`) === false
      // ) {
      //   this.toast.error("Make sure you're using a valid US phone number");
      //   return;
      // }

      // Enroll User via Quevis API
      await enroll(profile)
        .then(
          async ({ profileId }) => {
            if (this.isProfileValid(profileId)) {
              this.session.setProfileId(profileId);

              // Create Stripe Checkout Session
              await createStripeCheckoutSession().then((res) => {
                window.location.href = res.data.url;
                return res;
              });
            } else {
              this.toast.error(
                "Whoops! Something went wrong but we are on it now!"
              );
              console.error("Profile ID is undefined");
            }
          },
          ({ status }) => {
            if (status === 409) {
              this.toast.error(
                "Make sure you're using a unique phone number and email."
              );
            } else {
              this.toast.error("Oops... something went wrong.");
            }
            this.loading = false;
          }
        )
        .finally(() => (this.loading = false));
    },
  },
  computed: {
    fullName() {
      return this.form.first_name + " " + this.form.last_name;
    },
    fullAddress() {
      return (
        this.form.address.line1 +
        " " +
        this.form.address.line2 +
        " " +
        this.form.address.city +
        ", " +
        this.form.address.state +
        ", " +
        this.form.address.postal_code
      );
    },
  },
  async mounted() {
    document.body.classList.add("bg-cream-white");
    if (this.session.email) {
      this.form.email = this.session.email;
    }

    if (this.session.stripeCheckoutUrl === false) {
      this.session.abandonSession();
      this.$router.push({ path: "/" });
    }
  },
};
</script>
