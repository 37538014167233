<template>
  <section
    class="terms-of-use md:mt-24 mt-16 md:w-6/12 lg:w-2/3 container mx-auto md:p-0 px-4 relative"
  >
    <h1>Philippe Saisse Terms of Service</h1>
    <div class="WordSection1">
      <p><b>Last updated</b>: October 27, 2022</p>

      <p>
        Welcome to the Philippe Saisse Media App (the “<u>Application</u>”)! The
        Application is powered by Saisse Media, LLC, a subsidiary of Quevis, LLC
        (individually and collectively “We,” “Us,” or “Our”), which has engaged
        with Phipippe Saisse (the “<u>Artist</u>”) for the purpose of
        facilitating the delivery by the Artist of content and other Artist
        branded or endorsed materials to his/her Registered Fans (as defined
        below) through the Application. For purposes of these Terms of Service
        (these “<u>Terms</u>”), “<u>Services</u>” means, collectively, (i) the
        Application, (ii) any Application website and its related domains, (iii)
        any email notifications or other mediums, or portions of such mediums,
        through which you have accessed these Terms, and (v) any information,
        data, and content viewable on, contained in or downloadable from any of
        the foregoing (“<u>Content</u>”). “Content”, unless indicated otherwise
        in these Terms, does not include “Technology” (as defined below) for
        purposes of these Terms. Capitalized but undefined terms in these Terms
        will have the meanings ascribed to them in our <u>Privacy Policy</u>.
      </p>

      <p>
        <a></a>We update these Terms from time to time. If you are an Artist or,
        if you are not an Artist but have an active subscription to the
        Application (i.e. if you are a “<u>Registered Fan</u>”), then we will
        alert you of any material changes via a pop-up prior to or while you are
        accessing your Application account, or by email, in our discretion. In
        all cases, your continued use of any Services after we make changes is
        deemed to be acceptance of all changes.
      </p>

      <p>
        <b
          >Please read these Terms carefully. You accept these Terms by clicking
          to accept or agree to these Terms (when this option is made available
          to you), through use of the Services, or by continuing to use the
          Services after a change to these Terms.</b
        ><span style="font-size: 10pt"> </span
        ><b
          >If you do not agree with these Terms, or any portion of these Terms
          (including any portion of Our <u>Privacy Policy</u>), you must not
          access or use the Services.</b
        >
      </p>

      <p>
        <u>DISCLAIMER</u>: As further described in Sections 5, 6 and 18 below,
        all Content is made available to you on an “AS IS” basis without
        warranties of any kind, express or implied. We make no guarantee that
        any Content will be provided, and/or if provided, that it will done so
        in any particular manner or timeframe or that it will meet your
        requirements or otherwise be appropriate and unobjectionable.
        Additionally, Content and/or the scheduling of Content may be modified,
        postponed or canceled at any time for any or no reason (whether by or as
        the result of Us or the Artist) with or without notice.&nbsp;Content may
        not be accurate or complete and, due to changing circumstances or other
        causes, may change or ultimately prove to be inaccurate or incomplete.
        <b
          >You acknowledge that We do not control or direct a large portion of
          the Content available on the Services, and as a result you agree that
          we will not be responsible for the same, whether offensive,
          inappropriate, obscene, unlawful, or otherwise objectionable</b
        >.
      </p>

      <p>
        <b
          >PLEASE BE ADVISED THAT THESE TERMS CONTAIN AN AGREEMENT TO ARBITRATE
          ALL CLAIMS AND DISCLAIMERS OF WARRANTIES AND LIABILITY. THESE TERMS
          ALSO ALLOW YOU TO PURSUE CLAIMS AGAINST US ONLY ON AN INDIVIDUAL
          BASIS, AND NOT AS PART OF ANY CLASS OR REPRESENTATIVE ACTION OR
          PROCEEDING. AS A RESULT, YOU MAY SEEK RELIEF (INCLUDING MONETARY,
          INJUNCTIVE AND DECLARATORY RELIEF) ONLY ON AN INDIVIDUAL BASIS.</b
        >
      </p>

      <p>
        <b
          >WE MAY IMMEDIATELY TERMINATE YOUR ACCESS TO THE SERVICES (IN WHOLE OR
          IN PART) IF YOU FAIL TO COMPLY WITH ANY PROVISION OF THESE TERMS, IF
          WE BELIEVE YOUR USE OF ALL OR ANY PORTION OF THE SERVICES WILL REFLECT
          POORLY ON US OR THE ARTIST, THE SERVICES OR OUR OR HIS/HER GOODWILL,
          OR IF WE OTHERWISE DEEM YOUR USE OF THE SERVICES TO BE ILLEGAL OR
          OTHERWISE INAPPROPRIATE, IN EACH CASE, IN OUR SOLE AND ABSOLUTE
          DISCRETION.
        </b>
      </p>

      <p>
        <b><u>Table of Contents</u>:</b>
      </p>

      <ol start="1" type="1">
        <li>
          1. Relationship of these Terms to a Separate Existing Agreement with
          Us
        </li>
        <li>2. Description of the Services; Limitations</li>
        <li>3. Eligibility to Use the Services</li>
        <li>4. Use of the Services</li>
        <li>
          5. Acknowledgement Regarding Availability of the Services and Content
        </li>
        <li>6. User Generated Content</li>
        <li>7. Termination</li>
        <li>8. Fees and Payment</li>
        <li>9. The Mobile Application</li>
        <li>10. Third-Party Materials</li>
        <li>11. Ownership and Licenses</li>
        <li>12. Privacy</li>
        <li>13. Consent to Electronic Communication</li>
        <li>14. International Users</li>
        <li>15. Linking to the Services and Social Media Features</li>
        <li>16. Indemnification</li>
        <li>17. Disclaimers</li>
        <li>18. Limitations on Liability</li>
        <li>19. Our Remedies</li>
        <li>20. Legal Disputes</li>
        <li>21. Miscellaneous</li>
        <li>22. Contact Information</li>
      </ol>

      <p>
        <span class="pt-4" style="color: black">1. </span
        ><b
          ><u
            ><span style="color: black"
              >RELATIONSHIP OF THESE TERMS TO A SEPARATE EXISTING AGREEMENT WITH
              US.
            </span></u
          ></b
        >These Terms apply to all persons using or otherwise accessing the
        Services (in whole or in part), including Artists and Registered Fans.
        However, if you have entered into a separate written agreement with Us,
        such as in the case of the Artist, a Quevis App Development Agreement
        (an “<u>Existing Agreement</u>”), then these Terms only apply to you to
        the extent that they do not conflict with that Existing Agreement or to
        the extent that these Terms cover subject matter outside the scope of
        that Existing Agreement.
      </p>

      <p>
        <span class="pt-4" style="color: black">2. </span
        ><b
          ><u
            ><span style="color: black"
              >DESCRIPTION OF OUR ROLE IN THE SERVICES; LIMITATIONS.</span
            ></u
          ></b
        >
        We are a technology services provider engaged by the Artist to power the
        Application and related materials for the purpose of the Artist’s
        offering and provision of Content to Registered Fans. By using the
        Services, in whole or in part, you acknowledge and agree that: (a) We
        make no guaranty regarding or otherwise in respect of the Artist or any
        Registered Fans, including the Content to be offered and provided by the
        Artist (if any), or the number of Registered Fans (if any) that will
        purchase subscriptions to or otherwise make other purchases on, the
        Application or other portions of the Services; (b) We do not endorse,
        and make no representations or warranties regarding, the Artist, any
        Registered Fan or any Content including, without limitation, in the case
        of Content, the nature, timing or delivery thereof, or in the case of
        the Artist or any Registered Fan, the integrity, responsibility or
        actions of the Artist or any Registered Fan, whether in public, private
        or offline interactions; (c) your use and access to the Services
        (including any Content) is at your sole and exclusive risk, and you can
        and should conduct due diligence you deem necessary or appropriate
        before doing so; and (d) We are not and will not be responsible or
        liable for any acts or omissions of the Artist or any Registered Fan
        with which you engage on or through the Services, in whole or in part
        (as applicable). We do not employ the Artist or any Registered Fan and
        thus are not responsible for the conduct, whether online or offline, via
        the Application or otherwise, of the Artist or any Registered Fan.
        Content available on the Services is primarily user generated. We do not
        control or regularly vet any Content for accuracy. We do not assume and
        expressly disclaim any responsibility for the accuracy or reliability of
        any Content, and any liability that may result from any use of
        consumption thereof.
      </p>

      <span class="pt-4" style="color: black">3. </span
      ><b
        ><u
          ><span style="color: black">ELIGIBILITY TO USE THE SERVICES.</span></u
        ></b
      >
      <p class="pl-4">
        <span style="color: black">a.<span>&nbsp;&nbsp;&nbsp; </span></span
        ><u
          ><span style="color: black"
            >You have the Right to Enter into these Terms</span
          ></u
        ><span style="color: black"
          >. As an individual interacting with the Services in your individual
          capacity or on behalf of an entity, you represent and warrant that you
          have all right, power and authority to enter into these Terms on your
          own or such entity’s behalf and bind yourself or such entity, as
          applicable, to these Terms. If you are entering these Terms on behalf
          of an entity, all references in these Terms to “you” and “your” will
          mean such entity.</span
        >
      </p>

      <p class="pl-4">
        <span style="color: black">b.<span>&nbsp;&nbsp;&nbsp; </span></span
        ><u><span style="color: black">Consideration</span></u
        ><span style="color: black"
          >. You acknowledge that these Terms are supported by reasonable and
          valuable consideration, which you have received, and which is
          adequate. Such consideration includes your ability to access, use or
          interact with the Services. You represent and warrant that you have
          the capacity to be bound by these Terms.</span
        >
      </p>

      <p class="pl-4">
        <span style="color: black">c.<span>&nbsp;&nbsp;&nbsp; </span></span
        ><u><span style="color: black">Age</span></u
        ><span style="color: black">.</span><span> </span
        ><span style="color: black"
          >The Services are intended to be accessed and used only by adults and
          are not directed to minors. If you are below the age of 18, you may
          access and participate in the Services only under the supervision of a
          parent or legal guardian who agrees to be bound by these Terms and our
          <u>Privacy Policy</u>.</span
        >
      </p>

      <span style="color: black">4. </span
      ><b
        ><u><span style="color: black">USE OF THE SERVICES</span></u></b
      >
      <p class="pl-4">
        <a name="_heading=h.30j0zll"></a
        ><span style="color: black">a.<span>&nbsp;&nbsp;&nbsp; </span></span
        ><u><span style="color: black">Permitted Use</span></u
        ><span style="color: black">.</span><span> </span
        ><span style="color: black"
          >You will access and use the Services solely in accordance with the
          conditions and limitations set forth in these Terms (“<u
            >Permitted Use</u
          >”). The Artist may have one or more “<u>Authorized Users</u>,” which
          means collectively, the Artist and those persons expressly authorized
          by the Artist to access the Services on the Artist’s behalf.
          Authorized Users must be subject to confidentiality, use restrictions
          and intellectual property provisions at least as restrictive and
          protective of Us as those set forth in these Terms.</span
        >
      </p>

      <p class="pl-4">
        <a name="_heading=h.1fob9te"></a
        ><span style="color: black">b.<span>&nbsp;&nbsp;&nbsp; </span></span
        ><u><span style="color: black">Restrictions</span></u
        ><span style="color: black"
          >. You will not, and will not permit others to, directly or
          indirectly: (i) reverse engineer, decompile, disassemble,  decode,
          adapt, or otherwise attempt to discover the source code, object code
          or underlying structure, ideas, know-how or algorithms relevant to the
          Services and/or any underlying or related software, documentation or
          data (collectively, “<u>Technology</u>”); (ii) modify, translate, or
          create derivative works of, from or otherwise based on the Services or
          any Technology, in whole or in part; (iii) access and/or use the
          Services for timesharing or reselling purposes or otherwise for the
          benefit of a third party (except that Authorized Users may use the
          Services for the benefit of the Artist as expressly authorized by the
          Artist); (iv) upload to or otherwise use the Services to store or
          transmit infringing, libelous, or otherwise unlawful or tortious
          material, or material in violation of third-party rights, including
          privacy rights; (v) upload to or otherwise use the Services to store
          or transmit code, files, scripts, agents or programs intended to do
          harm, including, for example (but not by way of limitation), viruses,
          worms, time bombs and Trojan horses); (vi) interfere with or disrupt
          the integrity or performance of the Services or any Technology or
          Content (in whole or in part); (vii) attempt to gain unauthorized
          access to the Services, the Technology or any Content or any of their
          related systems or networks, or access or use the Services other than
          by an Authorized User through the use of his or her own then valid
          Access Credentials (as defined below); (viii) permit direct or
          indirect access to or use of the Services or any Content in a way that
          circumvents a contractual usage limit; (ix) frame or mirror the whole
          or any part of the Services (including any Technology or Content); (x)
          access the Services and/or the Technology (in whole or in part) in
          order to build a competitive product or service or for</span
        ><i><span style="color: #4472c4"> </span></i
        ><span style="color: black"
          >benchmarking or competitive analysis; (xi) remove any proprietary
          notices or labels of or from the Services, the Technology or any
          Content; (xii) access or use the Services in any way that violates
          these Terms, any third-party rights (including those of the Artist, as
          applicable), or any appliable rules, regulations or orders having the
          force of law (“<u>Laws</u>”), including, without limitation,
          anti-spam, export control, privacy, or&nbsp;anti-terrorism&nbsp;laws
          and regulations; (xiii) use the Services (in whole or in part), or any
          information contained therein in any way that is abusive, threatening,
          obscene, defamatory, libelous, or racially, sexually, religiously, or
          otherwise harassing, abusive, objectionable or offensive; (xiv)
          provide false or inaccurate information when registering an account on
          Us, using the Services or communicating with other registered users;
          (xiv) attempt to re-register with Us if we have terminated your
          account for any or no reason or terminate your registration and
          re-register in order to prevent a review from being associated with
          your account; (xv)</span
        ><span
          style="
            font-size: 9pt;
            font-family: 'Arial', sans-serif;
            color: #555555;
            background: white;
          "
        >
          d</span
        ><span style="color: black"
          >isrupt the normal flow of communication or otherwise act in a manner
          that negatively affects other users' ability to engage in real-time
          exchanges; (xvi) claim a relationship with or to speak for any
          individual, business, association, institution, or other organization
          for which you are not authorized to claim such a relationship; or
          (xvii) collect or store personal data about other&nbsp;users.
        </span>
      </p>

      <p class="pl-4">
        <span style="color: black">c.<span>&nbsp;&nbsp;&nbsp; </span></span
        ><u><span style="color: black">Your use of Content</span></u
        ><span style="color: black"
          >. Without limiting the generality of Section 4(b), except as
          expressly authorized by us or the Artist, you may not copy, reproduce,
          publish, distribute, modify, create derivative works of, rent, lease,
          sell, transfer, display, transmit, compile or collect, or in any
          manner commercially exploit any part of any Content, in whole or in
          part. You may not store any significant portion of any Content,
          whether archival files, computer-readable files, or any other medium.
          You also may not “mirror” any Content on any other server, except with
          our prior written consent. You may NOT download </span
        >or<span style="color: black">
          print any number of copies of Content</span
        >. N<span style="color: black"
          >o modifications of any of the Content are made except as may be
          expressly approved by us</span
        >.<span style="color: black">
          You acknowledge the applicable Content contributor (or his or her
          licensors) are and will remain the owners of all Content and that you
          do not acquire any intellectual property rights in such Content by
          downloading or printing such Content. Any rights to or in any Content
          that are not expressly granted by these Terms are reserved by the
          applicable Content contributor (or his or her licensors).</span
        >
      </p>

      <p class="pl-4">
        <span style="color: black">d.<span>&nbsp;&nbsp;&nbsp; </span></span
        ><u
          ><span style="color: black"
            >Your Submission of Information to Us</span
          ></u
        ><span style="color: black"
          >. To access the Services or parts thereof (such as the Application),
          you will be asked to provide certain registration details and other
          information. It is a condition of your use of the Services that all
          the information you provide on or via the Services is correct,
          current, and complete. You understand that our collection, use and
          disclosure of all such information is governed by our
          <u>Privacy Policy</u>, and you consent to all actions we take with
          respect to such information consistent with our Privacy Policy. We may
          (but are not obligated to) directly or through third parties, make any
          inquiries we consider necessary to validate your identity or other
          information provided by you to us.</span
        >
      </p>

      <p class="pl-4">
        <span style="color: black">e.<span>&nbsp;&nbsp;&nbsp; </span></span
        ><u><span style="color: black">Access Credentials</span></u
        ><span style="color: black"
          >. You may be issued a username, identification number, password,
          link, or security key, security token, PIN or other security code,
          method, technology or device used, alone or in combination, to verify
          an individual's identity and authorization to access and use the
          Services (“<u>Access Credentials</u>”). We encourage you to use strong
          Access Credentials (i.e., in the case of a password, one that is long,
          uses a mix of letters (upper and lower case), numbers and symbols, has
          no ties to your personal information, and no dictionary words) even if
          the Services permit simple Access Credentials. You have and will
          retain sole responsibility for the security and use of all Access
          Credentials, including for any losses that you or any third party may
          suffer as a result of the authorized or unauthorized use of any Access
          Credentials by any third party. We reserve the right to disable any
          Access Credentials at any time in Our discretion for any or no reason,
          including (without limitation) if, in Our opinion, you have violated
          any provision of these Terms.</span
        >
      </p>

      <p>
        <span style="color: black">5. </span
        ><b
          ><u
            ><span style="color: black"
              >ACKNOWLEDGEMENT REGARDING AVAILABILITY OF THE SERVICES AND
              CONTENT</span
            ></u
          ></b
        ><span style="color: black"
          >. We reserve the right to withdraw or amend all or any portion of the
          Services, and any service or material provided on the Services
          (including any Content), in our sole discretion with or without notice
          to you. Furthermore, your access and use of the Services or any
          Content may be interrupted from time to time for any of several
          reasons, including the malfunction of equipment, periodic updating,
          maintenance or repair of the Services or other actions that we or the
          Artist may, in our or his or her (as applicable) sole discretion,
          elect to take. We may also suspend or discontinue the availability of
          the Services or any portion or feature of the Services (including any
          Content) at any time in our sole discretion and without prior notice
          to you. From time to time, we may restrict access to some parts of the
          Services, or all of the Services, to users. We will not be liable if
          for any reason all or any part of the&nbsp;Services (including any
          Content)&nbsp;is unavailable at any time or for any period or at all.
          <b
            >FOR THE AVOIDANCE OF DOUBT, CONTENT DELIVERY IS NOT GUARANTEED, AND
            WE OR THE ARTIST MAY POSTPONE, CANCEL OR OTHERWISE MODIFY ANY
            CONTENT (EVEN IF SCHEDULED) AT ANY TIME FROM TIME TO TIME AND FOR
            ANY OR NO REASON WITH OR WITHOUT NOTICE.
          </b></span
        >
      </p>

      <p class="no-margin">
        <span>6. </span
        ><b
          ><u><span>USER GENERATED CONTENT. </span></u></b
        >Some or a large portion of Content available on or through the Services
        is generated by our users (collectively, “<u>User Generated Content</u
        >”), including Artists and Registered Fans. “User Generated Content”
        includes, without limitation, Artist content and other Artist branded or
        endorsed materials made available by or on the Artist’s behalf to
        Registered Fans, as well as text and other content contributed by
        non-Artist users to the Application, such as by Registered Fans during
        one or more chats and/or super chats.
        <b
          >You acknowledge that We do not control or direct User Generated
          Content, and as a result agree that will not be responsible or liable
          to you in respect of the same, even if offensive, inappropriate,
          obscene, unlawful, or otherwise objectionable</b
        >.
      </p>

      <p class="pl-4">
        <span style="color: black">a.<span>&nbsp;&nbsp;&nbsp; </span></span
        ><u
          ><span style="color: black"
            >Submission of User Generated Content</span
          ></u
        ><span style="color: black">.</span><span> </span>
      </p>

      <p class="pl-8">
        i. If you submit User Generated Content, then you acknowledge and agree
        that, once published or otherwise used by Us in accordance with our
        <u>Privacy Policy</u>, it cannot always be withdrawn. You assume all
        risks associated with any User Generated Content that you submit on or
        through the Services, including anyone's reliance on its quality,
        accuracy, or reliability, or any disclosure by you of information in
        such User Generated Content that makes you personally identifiable. By
        submitting such User Generated Content on or through the Services, you
        represent and warrant that you own, or have the necessary permissions to
        use and authorize the use of such User Generated Content as described in
        these Terms and our <u>Privacy Policy</u> and that such User Generated
        Content will complies with these Terms. You may not imply that such User
        Generated Content is in any way sponsored or endorsed by Us.
      </p>

      <p class="pl-8">
        ii. You acknowledge that any User Generated Content that you submit on
        or through the Services may expose you to liability. For example, but
        not by way of limitation, you may be exposed to liability if such User
        Generated Content contains material that is false, intentionally
        misleading, or defamatory; violates any third-party right, including any
        copyright, trademark, patent, trade secret, moral right, privacy right,
        right of publicity, or any other intellectual property or proprietary
        right; contains material that is unlawful, including illegal hate speech
        or pornography; exploits or otherwise harms minors; or violates or
        advocates the violation of any Laws.
      </p>

      <p class="pl-4">
        <span style="color: black">b.<span>&nbsp;&nbsp;&nbsp; </span></span
        ><u
          ><span style="color: black"
            >We Do Not Endorse User Generated Content</span
          ></u
        ><span style="color: black"
          >. We are not involved in the preparation or actual transmission of
          User Generated Content. As a result, We do not approve or endorse User
          Generated Content, and you acknowledge and agree that we: (i) have no
          control over the quality, correctness, timeliness, safety, truth,
          accuracy or legality of any User Generated Content posted by you or
          any other person or entity; and (ii) will have no liability to you as
          a result of your submission or posting, access or use of, or reliance
          on, such User Generated Content. User Generated Content posted by
          other users may be inaccurate. Additionally, you may find User
          Generated Content posted by other users to be offensive, harmful,
          indecent, or deceptive. Please use caution and common sense, and do
          not rely solely on User Generated Content.  Although We do not
          regularly review User Generated Content, We may, in our sole
          discretion and at any time, remove or edit any User Generated Content.
          You acknowledge and understand that We are under no obligation to
          enforce these Terms on your behalf against another user. Opinions,
          advice, statements, offers, or other information included in any User
          Generated Content, but not directly by Us, are those of their
          respective authors, who/which are solely responsible for such User
          Generated Content.</span
        >
      </p>

      <p class="pl-4">
        <span style="color: black">c.<span>&nbsp;&nbsp;&nbsp; </span></span
        ><u
          ><span style="color: black"
            >Our Use of User Generated Content</span
          ></u
        ><span style="color: black"
          >. Except as expressly set forth in an Existing Agreement applicable
          to you and excluding personally identifiable information of users
          provided to us during the registration process, all User Generated
          Content will be treated as non-confidential and non-proprietary.
          Additionally, we may use User Generated Content as set forth in Our
          <u>Privacy Policy</u>. We have no obligation to retain or provide you
          with copies of any User Generated Content submitted by you on or
          through the Services, nor do We guarantee any confidentiality with
          respect to such User Generated Content. Accordingly, once you submit
          User Generated Content to Us, you thereby irrevocably grant us a
          world-wide, perpetual, non-exclusive, royalty-free, assignable,
          sub-licensable, transferable license and right to use the same as set
          forth in this Section. Please note that, by submitting User Generated
          Content then: (A) you also irrevocably grant other Services users the
          license and right to access and use such User Generated Content as
          determined by Us in our sole discretion; and (b) waive, and cause to
          be waived, against us, and users and providers of the Services any
          claims and assertions of moral rights or attribution with respect to
          the same.</span
        ><span
          style="
            font-size: 9pt;
            font-family: 'Arial', sans-serif;
            color: #555555;
            background: white;
          "
        >
        </span>
      </p>

      <p class="pl-4">
        <span style="color: black">d.<span>&nbsp;&nbsp;&nbsp; </span></span
        ><u
          ><span style="color: black"
            >Deletion of User Generated Content</span
          ></u
        ><span style="color: black"
          >. You acknowledge and agree that we may delete any or all User
          Generated Content at any time in our sole discretion, and that copies
          of such User Generated Content may not be accessible, viewable or
          downloadable by you or any other user following such deletion.
          Accordingly, you must retain a copy of any User Generated Content
          uploaded by you if you want to access or use such User Generated
          Content in the future. We are not required to provide any notice of
          any such deletion, and will not liable for any failure to do so or
          otherwise in connection with any deletion of User Generated
          Content.</span
        >
      </p>

      <p class="pl-4">
        e. <span>&nbsp;&nbsp;&nbsp; </span>
        <u><span style="color: black">Copyright Infringement</span></u
        ><span style="color: black"
          >. We take claims of copyright infringement seriously. We will respond
          to notices of alleged copyright infringement that comply with
          applicable Law.
        </span>
      </p>

      <p class="pl-8">
        i. <u>Notification</u
        ><span style="color: black"
          >. If you believe any materials accessible on or from the Services
          infringe your copyright, you may request removal of those materials
          (or access to them) from the Services by submitting written
          notification to our Copyright Agent (designated below). In accordance
          with the Online Copyright Infringement Liability Limitation Act of the
          Digital Millennium Copyright Act (<i>17 U.S.C. § 512)</i> (the
          &quot;<u>DMCA</u>&quot;), Your written notice (the &quot;<u
            >DMCA Notice</u
          >&quot;) must include substantially the following:</span
        >
      </p>

      <p class="pl-10">
        <span>●</span>
        <span style="color: black">Your physical or electronic signature.</span>
      </p>

      <p class="pl-10">
        <span>●</span>
        <span style="color: black"
          >Identification of the copyrighted work you believe to have been
          infringed or, if the claim involves multiple works on the Services, a
          representative list of such works.</span
        >
      </p>

      <p class="pl-10">
        <span>●</span>
        <span style="color: black"
          >Identification of the material you believe to be infringing in a
          sufficiently precise manner to allow Us to locate that material.</span
        >
      </p>

      <p class="pl-10">
        <span>●</span>
        <span style="color: black"
          >Adequate information by which We can contact you (including your
          name, postal address, telephone number and, if available, e-mail
          address).</span
        >
      </p>

      <p class="pl-10">
        <span>●</span>
        <span style="color: black"
          >A statement that you have a good faith belief that use of the
          copyrighted material is not authorized by the copyright owner, its
          agent or the law.</span
        >
      </p>

      <p class="pl-10">
        <span>●</span>
        <span style="color: black"
          >A statement that the information in the written notice is
          accurate.</span
        >
      </p>

      <p class="pl-10">
        <span>●</span>
        <span style="color: black"
          >A statement, under penalty of perjury, that you are authorized to act
          on behalf of the copyright owner.</span
        >
      </p>

      <p>
        If you fail to comply with all of the requirements of the DMCA, your
        DMCA Notice may not be effective. Additionally, please be aware that, if
        you knowingly materially misrepresent that material or activity on the
        Services is infringing your copyright, you may be held liable for
        damages (including costs and attorneys' fees) under Section 512(f) of
        the DMCA.
      </p>

      <p class="pl-8">
        ii.
        <u><span style="color: black">Counter Notification Procedures</span></u
        ><span style="color: black"
          >. If you believe that material you posted on the Services was removed
          or access to it was disabled by mistake or misidentification, you may
          file a counter-notification with Us (a
          &quot;<u>Counter-Notice</u>&quot;) by submitting written notification
          to our Copyright Agent. Pursuant to the DMCA, a Counter-Notice must
          include substantially the following:</span
        >
      </p>

      <p class="pl-10">
        <span>●</span>
        <span style="color: black">Your physical or electronic signature.</span>
      </p>

      <p class="pl-10">
        <span>●</span>
        <span style="color: black"
          >An identification of the material that has been removed or to which
          access has been disabled and the location at which the material
          appeared before it was removed or access disabled.
        </span>
      </p>

      <p class="pl-10">
        <span>●</span>
        <span style="color: black"
          >Adequate information by which We can contact you (including your
          name, postal address, telephone number and, if available, e-mail
          address).</span
        >
      </p>

      <p class="pl-10">
        <span>●</span>
        <span style="color: black"
          >A statement under penalty of perjury by you that you have a good
          faith belief that the material identified above was removed or
          disabled as a result of a mistake or misidentification of the material
          to be removed or disabled.</span
        >
      </p>

      <p class="pl-10">
        <span>●</span>
        <span style="color: black"
          >A statement that you will consent to the jurisdiction of the Federal
          District Court for the judicial district in which your address is
          located (or if you reside outside the United States for any judicial
          district in which the Services may be found) and that you will accept
          service from the person (or an agent of that person) who provided the
          Services with the complaint at issue.</span
        >
      </p>

      <p>
        The DMCA allows us to restore the removed content if the party filing
        the original DMCA Notice does not file a court action against you within
        ten business days of receiving the copy of your Counter-Notice.
      </p>

      <p class="pl-8">
        iii. <u><span>Copyright Agent</span></u
        ><span style="color: black"
          >. Our designated Copyright Agent to receive DMCA Notices and
          Counter-Notices is:</span
        >
      </p>

      <p><span></span>Sauce Media LLC Legal Department</p>

      <p><span></span>401 E. California Ave. Suite 201 OKC, OK 73104</p>

      <p>support@philippesaise.com</p>

      <p>
        <span>7. </span
        ><b
          ><u><span>TERMINATION</span></u></b
        ><span style="color: black"
          >. You may terminate your use of the Services at any time (in whole or
          in part), provided that if you are a Registered Fan that has purchased
          a paid subscription, any fees prepaid by you will be non-refundable as
          set forth below. We and the Artist reserve the right, in Our or his or
          her (as applicable) sole discretion, to immediately terminate your
          access to all or part of the Services (including to any Content), to
          remove your profile and/or any User Generated Content posted by or
          about you from the Services, and/or to terminate your account, with or
          without notice for any reason or no reason in Our (or the Artist’s, as
          applicable) sole discretion, including, without limitation, if We
          determine that you are not eligible to use the Services, have violated
          these Terms, are not suitable for participation as a Registered Fan,
          have mis-used or mis-appropriated the Services or any Content (in
          whole or in part, and including any content made available thereon),
          including, but not limited to, use on a &quot;mirrored,&quot;
          competitive, or third-party site, or have otherwise violated any
          provision of these Terms. On termination, We will be under no
          obligation to provide you with a copy of any Content. If we terminate
          your use of the Services, we have no obligation to notify you of the
          reason, if any, for your termination.</span
        >
      </p>

      <p>
        <span>8. </span
        ><b
          ><u><span style="color: black">FEES AND PAYMENT</span></u></b
        ><span style="color: black">.</span>
      </p>

      <p class="pl-4">
        <span style="color: black"
          >a.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><u><span style="color: black">Fees, Pricing and Availability</span></u
        ><span style="color: black"
          >. In order to utilize certain portions of the Services, you may be
          required to pay a recurring subscription, one-time, or other fees. You
          will be responsible for any state or local sales taxes associated with
          purchases made by you on or through the Services. The Artist, and not
          us, sets the fees and availability for the applicable Content to be
          delivered to Registered Fans. We take a fee as consideration for
          facilitating the Artist’s delivery of content to Registered Fans
          through the Services.  Unless otherwise stated,</span
        ><span> </span
        ><span style="color: black"
          >all fees and pricing are stated in U.S. Dollars. Any rights purchased
          by you on or through the Services are personal to you and may not be
          transferred under any circumstances.</span
        >
      </p>

      <p class="pl-4">
        <span style="color: black"
          >b.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><u><span style="color: black">Billing and Payment</span></u
        ><span style="color: black"
          >. You agree to pay Us all charges associated with the subscription or
          Content you choose, as applicable, and as described on the Services at
          the time you submit your payment information. You also authorize Us,
          or a third-party payment processor that works on Our behalf, to charge
          your chosen payment method according to the terms of your chosen
          subscription or Content, as applicable. We reserve the right to
          correct any errors or mistakes even if we have already requested or
          received payment. By making any purchase on or through the Service,
          you represent and warrant that you are authorized to use the payment
          card provided and are capable of entering into a contract under
          applicable Law.</span
        >
      </p>

      <p class="pl-4">
        <span style="color: black"
          >c.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><u
          ><span style="color: black"
            >Automatic Subscription Renewal and Cancellation</span
          ></u
        ><span style="color: black">.</span>
      </p>

      <p class="pl-8">
        <span style="color: black">i. </span
        ><span style="color: black"
          >ALL PAID MEMBERSHIP SUBSCRIPTIONS AND RECURRING FEE-BASED PURCHASES
          WILL CONTINUE INDEFINITELY UNTIL </span
        >CANCELED<span style="color: black">
          BY THE USER. FOR PAID MEMBERSHIP SUBSCRIPTIONS, YOUR SUBSCRIPTION WILL
          AUTOMATICALLY RENEW AT THE END OF YOUR CHOSEN TERM PERIOD FOR AN
          ADDITIONAL EQUIVALENT PERIOD AT THE SUBSCRIPTION RATE AND FREQUENCY
          DISCLOSED TO YOU WHEN YOU ORIGINALLY SUBSCRIBED, UNLESS OTHERWISE
          PROVIDED AT THE TIME YOU SUBSCRIBED. IF YOU CHOOSE AN ANNUAL
          SUBSCRIPTION TERM, THEN YOU </span
        >ACKNOWLEDGE<span style="color: black">
          AND AGREE THAT WE WILL BILL YOU (AND YOU WILL PAY) THE FULL AMOUNT OF
          THE MONTHLY FEE FOR THE ENTIRE SUBSCRIPTION TERM AT THE TIME YOU
          PURCHASE YOUR SUBSCRIPTION. ANNUAL SUBSCRIPTIONS MAY BE OFFERED AT A
          DISCOUNT, IN WHICH CASE YOU ACKNOWLEDGE AND AGREE THAT ANY PREPAID
          FEES ARE NON-REFUNDABLE AND NOTWITHSTANDING TERMINATION OF YOUR
          SUBSCRIPTION FOR ANY REASON. FOR OTHER PURCHASES WITH RECURRING FEES,
          YOU WILL BE CHARGED THE FEE ASSOCIATED WITH SUCH PURCHASE AT THE
          INTERVAL SPECIFIED AT THE TIME OF PURCHASE, UNTIL YOU EXPRESSLY
          CANCEL.</span
        >
      </p>

      <p class="pl-8">
        <span>ii. </span
        ><span style="color: black"
          >You may cancel our update your paid membership subscription at any
          time [by following the instructions on your account settings page]. If
          you cancel a paid subscription, you typically will be permitted to use
          your subscription until the end of your then-current subscription
          term. Your paid subscription will not be renewed after your
          then-current term expires.</span
        >
      </p>

      <p class="pl-4">
        <span style="color: black"
          >d.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><u><span style="color: black">Freemium Subscriptions</span></u
        ><span style="color: black"
          >. We or the Artist may from time to time make all or certain portions
          of the Services available at no charge (“<u>Freemium Subscriptions</u
          >”). If you register for one or more Freemium Subscriptions, you
          acknowledge and agree that you may have limited access to the Services
          and/or features thereof.</span
        >
      </p>

      <p class="pl-4">
        <span style="color: black"
          >e.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><u><span style="color: black">No Refunds</span></u
        ><span style="color: black"
          >.
          <b
            >Except as We may otherwise agree in writing, all payments for
            subscriptions, services or products made on or through the Services
            are non-refundable, and there are no refunds or credits for unused
            or partially used subscriptions, services or products, even if you
            cancel your membership or a subscription, service, or product in the
            middle of a term.</b
          ></span
        >
      </p>

      <p>
        <span style="color: black">9. </span
        ><b
          ><u><span style="color: black">THE MOBILE APPLICATION</span></u></b
        ><span style="color: black">.</span><span> </span
        ><span style="color: black"
          >The Services include access and other capabilities in connection with
          the mobile Application (collectively, “<u>App Services</u>”).
          Currently, we expect to make Our App Services available only to
          Artists and Registered Fans. Please note that App Services may not
          contain or permit all features and/or functionalities associated with
          other parts of the Services.</span
        >
      </p>

      <p class="pl-4">
        <span style="color: black"
          >a.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><u><span style="color: black">Certain Acknowledgements</span></u
        ><span style="color: black"
          >. By using App Services, you: (i) acknowledge that these Terms are
          between you and Us and not with Apple, Inc., Google, Inc. or any other
          third-party; (ii) agree not to use or manipulate App Services on your
          mobile device while driving or operating any other heavy machinery;
          (iii) acknowledge that certain parts of App Services may require phone
          service, data access or text messaging capability. Except as otherwise
          noted, carrier rates for phone, data and text messaging will apply;
          and (iv) acknowledge that We may, in our sole discretion and at any
          time, change, suspend, remove or disable access to Content or other
          materials comprising part of App Services at any time without notice.
          In no event will We be liable for making these changes. We may also
          impose limits on the use or access of certain features or portions of
          App Services, in any case without notice or liability.</span
        >
      </p>

      <p class="pl-4">
        <span style="color: black"
          >b.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><u><span style="color: black">Scope of License</span></u
        ><span style="color: black"
          >. Any App Services made available are licensed, not sold, to you.
          Your license to the App Services is subject to your prior acceptance
          of these Terms and you agree that these Terms will apply to the App
          Services that you license. We reserve all rights in and to any App
          Services not expressly granted to you under these Terms. The license
          granted to you for any App Services is a limited, non-exclusive and
          nontransferable license to: (i) download, install and use the App
          Services for your personal, non-commercial use on a single, compatible
          mobile device that you own or control (“<u>Mobile Device</u>”), as
          permitted by these Terms and subject to any additional rules and
          restrictions imposed upon you by third parties, such as rules and
          restrictions imposed by your mobile device provider and your mobile
          application store provider (“<u>Third-Party Rules</u>”); and (ii)
          access, stream, download and use on such Mobile Device and the Content
          made available in or otherwise accessible through the App Services,
          strictly in accordance with these Terms. For the avoidance of doubt,
          this license does not allow you to use any App Services on any Mobile
          Device that you do not own or control, and you may not distribute or
          make any App Services available over a network where it could be used
          by multiple devices at the same time. If you sell or otherwise
          transfer your Mobile Device to a third party, you must remove any App
          Services from the Mobile Device before doing so.</span
        >
      </p>

      <p class="pl-4">
        <span style="color: black"
          >c.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><u><span style="color: black">Updates</span></u
        ><span style="color: black"
          >. We may, at any time and from time to time with or without notice to
          you, in our sole discretion develop and provide App Services updates,
          which may include upgrades, bug fixes, patches and other error
          corrections or new features (collectively, including related
          documentation, “<u>Updates</u>”). Updates may also modify or delete in
          their entirety certain features and functionality. We have no
          obligation to provide any Updates or to continue to provide or enable
          any particular features or functionality. Based on your Mobile Device
          settings, when your Mobile Device is connected to the internet either:
          (i) the Application will automatically download and install all
          available Updates; or (ii) you may receive notice of or be prompted to
          download and install available Updates. You must promptly download and
          install all Updates and acknowledge and agree that the App Services or
          portions of such App Services may not properly operate should you fail
          to do so. The terms of this license will govern any Updates, unless
          such Update is accompanied by a separate license in which case the
          terms of that license will govern.</span
        >
      </p>

      <p>
        <span style="color: black">10.<span>&nbsp;&nbsp; </span></span
        ><b
          ><u><span style="color: black">THIRD PARTY MATERIALS</span></u></b
        ><span style="color: black">.</span>
      </p>

      <p class="pl-4">
        <span style="color: black"
          >a.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><u><span style="color: black">Third-Party Materials</span></u
        ><span style="color: black"
          >. You acknowledge and agree that: (i) one or more of the
          functionalities, services or merchandise available on or via the
          Services may made available by third parties (“<u
            >Third-Party Service Providers</u
          >” and such functionalities, services or merchandise, “<u
            >Third-Party Materials</u
          >”); (ii) certain aspects of the Services rely on API integration for
          certain features and functions, but that API integration has its own
          inherent level of unpredictability and inconsistency that is out of
          Our control, and that as such We will have no liability for downtime
          of any Services caused by API integration failures; (iii) Third-Party
          Service Providers may impose restrictions on purchase and/or use of
          the particular Third-Party Material, in addition to other terms and
          conditions, including without limitation, those set forth in any
          applicable terms and conditions agreed to by or otherwise made
          available to you (collectively, “<u>Third-Party Requirements</u>”);
          (iv) you are solely responsible for compliance with, and will ensure
          that you and all Authorized Users comply with, all Third-Party
          Requirements; and (v) We may at any time terminate and/or discontinue
          any Third-Party Materials, including as a result of termination of Our
          relationship with the applicable Third-Party Service Provider. ALL
          THIRD PARTY MATERIALS ARE MADE AVAILABLE VIA THE SERVICES “AS IS” AND
          WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED OR OTHER.</span
        >
      </p>

      <p class="pl-4">
        <span style="color: black"
          >b.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><u
          ><span style="color: black"
            >Links to Third-Party Sites or Content</span
          ></u
        ><span style="color: black"
          >. Links from the Services to external sites or inclusion of
          advertisements and other third-party content on the Services
          (including User Generated Content), do not constitute an endorsement
          by Us of such sites or the content, products, advertising and other
          materials presented on such sites or of the products and services that
          are the subject of such third-party content, but are for Our users’
          reference and convenience. We do not control third-party sites or
          content, and as a result are not and will not be responsible for them.
          Such sites and content are governed by their respective owners’ terms
          of use and privacy policies, and not these Terms (including Our
          Privacy Policy). We expressly disclaim any liability derived from the
          use and/or viewing of such links or content that may appear on the
          Services, and you hereby agree to hold Us harmless from any liability
          that may result from the use of such links or content.</span
        >
      </p>

      <p
        style="
          margin-bottom: 0in;
          text-align: justify;
          line-height: normal;
          border: none;
        "
      >
        <span style="color: black">&nbsp;</span>
      </p>

      <p>
        <span style="color: black">11.<span>&nbsp;&nbsp; </span></span
        ><b
          ><u><span style="color: black">OWNERSHIP AND LICENSES</span></u></b
        ><span style="color: black">.</span>
      </p>

      <p class="pl-4">
        <span style="color: black"
          >a.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><u><span style="color: black">Ownership</span></u
        ><span style="color: black"
          >. As between you and Us, you will own all right, title and interest
          in and to content, materials or data provided by you or your
          Authorized Users to Us or via the Services, including any User
          Generated Content submitted by you to us (collectively, “<u
            >Your Materials</u
          >”). We or Our licensors will own and retain all right, title and
          interest in and to the following (collectively, “<u>Our Property</u
          >”): (i) the Services, including the Application, the Technology, and
          all improvements, enhancements or modifications to any of the
          foregoing; (ii) any work product, including any software,
          applications, inventions or other technology or intellectual property
          developed, authored and/or reduced to practice in connection with the
          Services (“<u>Results</u>”); (iii) the “Quevis” and </span
        >“Saisse Media”<span style="color: black">
          names, brands, marks and other similar intellectual property; (iv) any
          suggestion, enhancement request, recommendation, correction or other
          feedback provided by you or your Authorized Users relating to the
          subject matter of these Terms (collectively, “<u>Feedback</u>”); (v)
          any and all performance data, test or evaluation results, or other
          metrics derived from the Services, including the Application and
          Aggregated Data (as defined below); and (f) all intellectual property
          rights related to any of the foregoing. We expressly reserve all other
          rights in and to the foregoing. During and after the term of your use
          of the Services, each of you and We will cooperate with the other to
          do any and all things which reasonably necessary or desirable to
          establish, maintain, protect and enforce Our or your exclusive
          ownership of the property identified in this Section.</span
        >
      </p>

      <p class="pl-4">
        <span style="color: black"
          >b.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><u><span style="color: black">Use of Data</span></u
        ><span style="color: black"
          >. Notwithstanding anything to the contrary, and to the extent not
          prohibited by Law, We will have the right to collect and analyze Your
          Materials and other information relating to the provision, use and
          performance of various aspects of the Services (including the
          Application and Technology, and including, without limitation, Your
          Materials and data derived therefrom), and will be free (during and
          after the term of your use of the Services) to: (i) use such
          information and data to improve and enhance the Services and for other
          development, diagnostic and corrective purposes in connection with the
          Services, including the Application, the Technology and/or Our other
          product or service offerings; and (ii) use and disclose such
          information and data solely in aggregate or other de- identified form
          in connection with Our business without disclosing your identity, or
          the identity of any of your individual Authorized Users (“<u
            >Aggregated Data</u
          >”). No rights or licenses are granted except as expressly set forth
          herein.</span
        >
      </p>

      <p class="pl-4">
        <span style="color: black"
          >c.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><u><span style="color: black">Feedback</span></u
        ><span style="color: black"
          >. Any improvements, enhancements or other modifications created,
          prepared, produced, authored, edited, amended, conceived or reduced to
          practice by Us (whether alone or together with you or any other third
          party or parties) arising out of or relating to Feedback are and will
          remain Our sole and exclusive property.</span
        >
      </p>

      <p>
        <span style="color: black">12.<span>&nbsp;&nbsp; </span></span
        ><b
          ><u><span style="color: black">PRIVACY</span></u></b
        ><span style="color: black"
          >. We use Your Information as set forth in our&nbsp;<u
            >Privacy Policy</u
          >. Our Privacy Policy is hereby incorporated into these Terms by
          reference.</span
        >
      </p>

      <p>
        <span style="color: black">13.<span>&nbsp;&nbsp; </span></span
        ><b
          ><u
            ><span style="color: black"
              >CONSENT TO ELECTRONIC COMMUNICATION</span
            ></u
          ></b
        ><span style="color: black"
          >. By using the Services, you agree to allow Us to communicate with
          you electronically, and you consent to electronic delivery of notices,
          documents, and other materials from Us or the Services, via the
          Application or e-mail. If you are an Artist or a Registered Fan, you
          also agree to check your account for alerts and messages, and the
          e-mail account reflected on your account on a reasonably regular basis
          to stay apprised of important notices and information about your
          account.</span
        >
      </p>

      <p>
        <span style="color: black">14.<span>&nbsp;&nbsp; </span></span
        ><b
          ><u><span style="color: black">INTERNATIONAL USERS</span></u></b
        ><span style="color: black"
          >. The Services are controlled, operated and administered by us from
          our offices within the United States of America. We make no
          representations that the Services or any Content are permissible,
          appropriate or available for use in other jurisdictions. If </span
        >you use the<span style="color: black">
          Services or any Content from a location outside the United States,
          then you do so by your own volition and you are solely responsible for
          compliance with all applicable Laws (including local laws and any
          applicable United States export control laws). You must not access or
          use the Services or any Content in a manner prohibited by any
          applicable Laws.</span
        >
      </p>

      <p>
        <span style="color: black">15.<span>&nbsp;&nbsp; </span></span
        ><b
          ><u
            ><span style="color: black"
              >LINKING TO THE SERVICES AND SOCIAL MEDIA FEATURES</span
            ></u
          ></b
        ><span style="color: black">.</span>
      </p>

      <p class="pl-4">
        <span style="color: black"
          >a.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span style="color: black"
          >You may link to certain portions of the Services, provided you do so
          in a way that is fair and legal and does not damage our reputation or
          take advantage of it, but you must not establish a link in such a way
          as to suggest any form of association, approval or endorsement on Our
          part without Our prior written consent.
          <a name="bookmark=id.3znysh7"></a
        ></span>
      </p>

      <p class="pl-4">
        <span style="color: black"
          >b.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span style="color: black"
          >The Services may provide certain social media features that enable
          you to: link from your own or certain third-party websites to certain
          content available on the Services; send e-mails or other
          communications with certain content, or links to certain content
          available on the Services; or cause limited portions of Content to be
          displayed or appear to be displayed on your own or certain third-party
          websites.<a name="bookmark=id.2et92p0"></a> You may use these features
          solely as they are provided by Us, solely with respect to the content
          with which they are displayed, and otherwise in accordance with these
          Terms and any additional terms and conditions that We may provide with
          respect to such features. Subject to the foregoing, you must not: (i)
          establish a link from any website that is not owned by you; (ii) use
          the Services or portions of it to be displayed, or appear to be
          displayed by, for example, framing, deep linking or in-line linking,
          on any website; or (iii) otherwise take any action with respect to the
          materials on available on the Services that is inconsistent with any
          other provision of these Terms.<a name="bookmark=id.tyjcwt"></a
        ></span>
      </p>

      <p class="pl-4">
        <span style="color: black"
          >c.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span style="color: black"
          >Any website from which you are linking, or on which you make certain
          Content accessible, must comply in all respects with these Terms.<a
            name="bookmark=id.3dy6vkm"
          ></a
          >&nbsp;You agree to cooperate with Us in causing any unauthorized
          framing or linking to immediately cease. We reserve the right to
          withdraw linking permission without notice.<a
            name="bookmark=id.1t3h5sf"
          ></a
          >&nbsp;We may disable all or any social media features and any links
          at any time without notice </span
        >at our<span style="color: black"> discretion.</span>
      </p>

      <p>
        <span style="color: black">16.<span>&nbsp;&nbsp; </span></span
        ><b
          ><u><span style="color: black">INDEMNIFICATION</span></u></b
        ><span style="color: black"
          >. You will indemnify, defend and hold Us harmless from and against
          any and all claims, losses, damages, judgments, liabilities costs, and
          expenses (including attorneys’ fees and the costs of enforcing this
          provision and of pursuing any insurance providers) arising from or
          relating to: (i) any of Your Materials, including any use, disclosure
          or storage of the same by Us or on Our behalf in accordance with these
          Terms; (ii) your failure to comply with any applicable Laws, or any of
          your other obligations, covenants, representations and warranties set
          forth in these Terms; or (iii) your access or use of the Services or
          any Content, in whole or in part.
        </span>
      </p>

      <p>
        <span style="color: black">17.<span>&nbsp;&nbsp; </span></span
        ><b
          ><u><span style="color: black">DISCLAIMERS</span></u></b
        ><span style="color: black"
          >.
          <b
            >THE SERVICES (INCLUDING THE TECHNOLOGY AND ALL CONTENT) ARE
            PROVIDED “AS IS” AND WE EXPRESSLY DISCLAIM ALL WARRANTIES, EXPRESS,
            IMPLIED, STATUTORY OR OTHER, INCLUDING, BUT NOT LIMITED TO, IMPLIED
            WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
            TITLE AND NON-INFRINGEMENT, AND ANY AND ALL WARRANTIES ARISING FROM
            COURSE OF DEALING, USAGE OR TRADE PRACTICE WITH RESPECT TO THE SAME.
            Without limiting the generality of the foregoing:
          </b></span
        >
      </p>

      <p>
        <span style="color: black"
          >a.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><b
          ><span style="color: black"
            >You acknowledge that all or a portion of the Services and any
            Content may be temporarily unavailable for any reason, including
            scheduled maintenance, unscheduled emergency maintenance, force
            majeure or for other reasons affecting us, the Artist (as
            applicable) or our other applicable Third Party Service Providers,
            or because of other causes beyond Our or the Artist’s control. WE DO
            NOT WARRANT THAT ACCESS TO THE SERVICES OR ANY CONTENT WILL BE
            UNINTERRUPTED OR ERROR FREE; NOR DO WE MAKE ANY WARRANTY THAT THE
            SERVICES, ANY CONTENT (IN WHOLE OR IN PART) WILL BE PROVIDED AT ANY
            PARTICULAR </span
          >INTERVALS<span style="color: black">
            OR AT ALL, OR IF PROVIDED, WILL MEET YOUR OR ANY OTHER PERSON’S
            REQUIREMENTS, ACHIEVE ANY </span
          >PARTICULAR<span style="color: black">
            RESULT, INCLUDING YOUR COMPLIANCE WITH ANY APPLICABLE LAWS, BE
            COMPATIBLE OR WORK WITH ANY SOFTWARE, SYSTEM OR OTHER SERVICES, BE
            SECURE, ACCURATE, COMPLETE, FREE OF HARMFUL CODE OR ERROR FREE, OR
            BE AGE APPROPRIATE OR UNOFFENSIVE.
          </span></b
        >
      </p>

      <p>
        <span style="color: black"
          >b.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><b
          ><span style="color: black"
            >WE DO NOT: (i) WARRANT THE ACCURACY, ADEQUACY, COMPLETENESS OR
            APPROPRIATENESS OF ANY CONTENT AVAILABLE ON, THROUGH OR AS A RESULT
            OF THE SERVICES AND HEREBY EXPRESSLY DISCLAIM ANY LIABILITY FOR THE
            SAME, INCLUDING ANY ERRORS OR OMISSIONS OR UNLAWFUL OR OTHERWISE
            OFFENSIVE ACTIVITIES DEPICTED IN THE SAME; OR (ii) ADOPT, ENDORSE OR
            ACCEPT RESPONSIBILITY FOR THE ACCURACY OR RELIABILITY OF ANY
            OPINION, ADVICE, OR STATEMENT MADE BY ANY PARTY OTHER THAN US
            (INCLUDING THE ARTIST). ALL CONTENT AVAILABLE ON, THROUGH OR AS A
            RESULT OF THE SERVICES IS PROVIDED FOR YOUR INFORMATIONAL PURPOSES
            ONLY. THIS INFORMATION IS PROVIDED &quot;AS IS&quot; WITHOUT
            WARRANTY OF ANY KIND. IN NO EVENT WILL WE BE LIABLE IN ANY WAY WITH
            REGARD TO SUCH CONTENT. YOUR CONSUMPTION OR OTHER USE OF ANY SUCH
            CONTENT IS AT YOUR SOLE AND EXCLUSIVE RISK.</span
          ></b
        >
      </p>

      <p>
        <span style="color: black"
          >c.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><b
          ><span style="color: black"
            >NO INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM THE
            SERVICES OR THE CONTENT (</span
          >INCLUDING<span style="color: black">
            AN USER GENERATED CONTENT), WILL CREATE ANY WARRANTY REGARDING US OR
            ANY OF THE SERVICES THAT IS NOT EXPRESSLY STATED IN THESE TERMS. YOU
            USE THE SERVICES, AND USE, ACCESS, DOWNLOAD, OR OTHERWISE OBTAIN THE
            CONTENT (INCLUDING ALL USER GENERATED CONTENT AND OR OTHER CONTENT
            AVAILABLE THROUGH THE SERVICES), AT YOUR OWN DISCRETION AND RISK.
            YOU ASSUME ALL RISK FOR ANY DAMAGE THAT MAY RESULT FROM YOUR USE OF
            OR ACCESS TO THE SERVICES OR ANY CONTENT, YOUR DEALINGS WITH ANY
            THIRD PARTY, AND YOUR CONSUMPTION OR OTHER USE OF ANY CONTENT
            (INCLUDING ALL USER GENERATED CONTENT OR OTHER CONTENT AVAILABLE
            THROUGH THE SERVICES). YOU ARE SOLELY RESPONSIBLE FOR ANY DAMAGE TO
            YOUR PERSON OR PROPERTY, OR THE LOSS THEREOF OR OF ANY DATA THAT MAY
            RESULT FROM THE USE OF THE SERVICES OR THE USE OF ANY CONTENT
            (INCLUDING USER GENERATED CONTENT OR OTHER CONTENT AVAILABLE THROUGH
            THE SERVICES). We do not control or direct User Generated Content,
            and as a result will not be responsible for the same, whether
            offensive, inappropriate, obscene, unlawful, or otherwise
            objectionable.</span
          ></b
        >
      </p>

      <p>
        <span style="color: black"
          >d.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><b
          ><span style="color: black"
            >ALL THIRD-PARTY MATERIALS INCLUDED IN OR OTHERWISE MADE AVAILABLE
            VIA THE SERVICES ARE PROVIDED &quot;AS IS&quot; AND SUBJECT TO ANY
            APPLICABLE THIRD-PARTY SERVICE PROVIDER TERMS AND CONDITIONS. ANY
            REPRESENTATION OR WARRANTY OF OR CONCERNING ANY THIRD-PARTY SERVICES
            IS STRICTLY BETWEEN YOU AND THE THIRD-PARTY SERVICE PROVIDER.</span
          ></b
        >
      </p>

      <p>
        <span style="color: black">18.<span>&nbsp;&nbsp; </span></span
        ><b
          ><u><span style="color: black">LIMITATIONS ON LIABILITY</span></u></b
        ><span style="color: black">.</span>
      </p>

      <p class="pl-4">
        <span style="color: black"
          >a.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span style="color: black"
          >IN NO EVENT WILL WE OR ANY OF OUR LICENSORS (INCLUDING THE ARTIST),
          SERVICE PROVIDERS OR SUPPLIERS BE LIABLE UNDER OR IN CONNECTION WITH
          THESE TERMS OR THE PROVISION OF THE SERVICES OR ANY CONTENT TO UNDER
          ANY LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT, TORT
          (INCLUDING NEGLIGENCE), STRICT LIABILITY AND OTHERWISE, FOR ANY: (i)
          LOSS OF PRODUCTION, USE, BUSINESS, REVENUE OR PROFIT OR DIMINUTION IN
          VALUE; (ii) IMPAIRMENT, INABILITY TO USE OR LOSS, INTERRUPTION OR
          DELAY OF THE SERVICES OR ANY CONTENT (IN WHOLE OR IN PART); (iii)
          LOSS, DAMAGE, CORRUPTION OR RECOVERY OF YOUR DATA, OR A BREACH OF DATA
          OR SYSTEM SECURITY; OR (iv) CONSEQUENTIAL, INCIDENTAL, INDIRECT,
          EXEMPLARY, SPECIAL, ENHANCED OR PUNITIVE DAMAGES, IN EACH CASE,
          REGARDLESS OF WHETHER SUCH PERSONS WERE ADVISED OF THE POSSIBILITY OF
          SUCH LOSSES OR DAMAGES OR SUCH LOSSES OR DAMAGES WERE OTHERWISE
          FORESEEABLE, AND NOTWITHSTANDING THE FAILURE OF ANY AGREED OR OTHER
          REMEDY OF ITS ESSENTIAL PURPOSE.</span
        >
      </p>

      <p class="pl-4">
        <span style="color: black"
          >b.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span style="color: black"
          >IN NO EVENT WILL WE OR ANY OF OUR LICENSORS (INCLUDING THE ARTIST) BE
          LIABLE FOR ANY DAMAGES WHATSOEVER, WHETHER DIRECT, INDIRECT, GENERAL,
          SPECIAL, COMPENSATORY, CONSEQUENTIAL, OR OTHERWISE, ARISING OUT OF OR
          RELATING TO THE CONDUCT OF YOU OR ANYONE ELSE IN CONNECTION WITH THE
          CONSUMPTION OR USE OF THE SERVICES OR ANY CONTENT, INCLUDING WITHOUT
          LIMITATION, FINANCIAL LOSS, BODILY INJURY, EMOTIONAL DISTRESS, AND/OR
          ANY OTHER DAMAGES RESULTING FROM ANYONE'S CONSUMPTION OF OR RELIANCE
          ON CONTENT AVAILABLE BY OR AS A RESULT OF THE SERVICES.</span
        >
      </p>

      <p class="pl-4">
        <span style="color: black"
          >c.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span style="color: black"
          >IN NO EVENT WILL THE COLLECTIVE AGGREGATE LIABILITY OF OURS AND OUR
          LICENSORS (INCLUDING THE ARTIST_, SERVICE PROVIDERS AND SUPPLIERS
          UNDER OR IN CONNECTION WITH THESE TERMS OR ITS SUBJECT MATTER, UNDER
          ANY LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT, TORT
          (INCLUDING NEGLIGENCE), STRICT LIABILITY AND OTHERWISE, EXCEED THE
          AMOUNTS PAID BY YOU TO US UNDER THESE TERMS DURING THE TWELVE-MONTH
          PERIOD IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO THE CLAIM OR, IF
          YOU HAVE NOT PAID US FOR THE USE OF ANY SERVICES, THE AMOUNT OF
          $25.00. THE FOREGOING LIMITATION APPLIES NOTWITHSTANDING THE FAILURE
          OF ANY AGREED OR OTHER REMEDY OF ITS ESSENTIAL PURPOSE.</span
        >
      </p>

      <p class="pl-4">
        <span style="color: black"
          >d.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span style="color: black"
          >Certain state laws do not allow limitations on implied warranties or
          the exclusion or limitation of certain damages. If these state laws
          apply to you, some or all of the above disclaimers, exclusions or
          limitations may not apply to you. In the event that the foregoing is
          determined or held to be inapplicable or unenforceable by any court,
          arbitration panel or other tribunal, then the statute of limitations
          for the State of Florida, including Chapter 95, Florida Statutes, will
          apply to any such action, claim, dispute or proceeding referred to
          final or binding arbitration.</span
        >
      </p>

      <p>
        <span style="color: black">19.<span>&nbsp;&nbsp; </span></span
        ><b
          ><u><span style="color: black">OUR REMEDIES</span></u></b
        ><span style="color: black"
          >. You acknowledge that We may be irreparably damaged if these Terms
          are not specifically enforced, and damages at law would be an
          inadequate remedy. Therefore, in the event of a breach or threatened
          breach of any provision of these Terms by you, We will be entitled, in
          addition to all rights and remedies, to an injunction restraining such
          breach or threatened breach, without being required to show any actual
          damage or to post an injunction bond, or to a decree for specific
          performance of the provisions of these Terms. You agree that
          (notwithstanding Section 20 below) we may bring any action or
          proceeding with regard to such injunction restraining such breach or
          threatened breach in the courts of record of Palm Beach County,
          Florida, or the United States District Court, Southern District of
          Florida, West Palm Beach Division. You consent to personal
          jurisdiction over you by such court and to the exclusive jurisdiction
          of such court, and waive any objection to the laying of venue of any
          such action or proceeding in such court.
        </span>
      </p>

      <p>
        <span style="color: black">20.<span>&nbsp;&nbsp; </span></span
        ><b
          ><u><span style="color: black">LEGAL DISPUTES</span></u></b
        ><span style="color: black">.</span>
      </p>

      <p class="pl-4">
        <span style="color: black"
          >a.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><u><span style="color: black">Applicable Law</span></u
        ><span style="color: black"
          >. These Terms are governed by and construed in accordance with the
          internal laws of the State of Florida, without regard to conflicts of
          law principles. You agree that the Services will be deemed: (i) based
          solely in the State of Florida; and (ii) a passive service that does
          not give rise to personal jurisdiction over us and our assigns, either
          specific or general, in jurisdictions other than the State of
          Florida.</span
        >
      </p>

      <p class="pl-4">
        <span style="color: black"
          >b.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><u><span style="color: black">Agreement to Arbitrate</span></u
        ><span style="color: black"
          >. Any civil action, claim, dispute or proceeding arising out of or
          relating to this or any previous version of these Terms, your use of
          or access to the Services, including any Content (except for an
          injunctive action regarding a breach or threatened breach of any
          provision of these Terms by you as provided above) will be resolved
          exclusively through final and binding arbitration, before a single
          arbitrator, rather than in court.
        </span>
      </p>

      <p class="pl-8">
        <span style="color: black"
          >i.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span style="color: black"
          >Before initiating arbitration, you acknowledge and agree that you
          will first give us an opportunity to resolve your problem or dispute.
          This includes sending a written description of your problem or dispute
          to us including, but not limited to, information or representations
          related to the Services and upon which you rely to </span
        >support@philippesaise.com<span style="color: black">
          Attn: Legal Dispute. You agree to negotiate with us in good faith
          about your problem or dispute.</span
        >
      </p>

      <p class="pl-8">
        <span style="color: black"
          >ii.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span style="color: black"
          >Arbitration is more informal than a lawsuit in court. There is no
          judge or jury in arbitration, and court review of an arbitration award
          is very limited. However, an arbitrator can award the same damages and
          relief on an individual basis that a court can award to an individual.
          You and We agree that the arbitrator will apply the terms of these
          Terms as a court would.
        </span>
      </p>

      <p class="pl-8">
        <span style="color: black"
          >iii.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span style="color: black"
          >The arbitrator, and not any federal, state or local court or agency,
          will have exclusive authority to resolve any dispute arising out of or
          relating to the interpretation, applicability, enforceability or
          formation of these Terms, and to arbitrate any part of these Terms,
          including any claim that all or any part of this section or these
          Terms is void or voidable.
        </span>
      </p>

      <p class="pl-8">
        <span style="color: black"
          >iv.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span style="color: black"
          >The arbitration will be conducted by the American Arbitration
          Association (the “<u>AAA</u>”) exclusively in Palm Beach County,
          Florida, under the AAA’s rules and procedures, including the AAA’s
          Consumer Arbitration Rules (as applicable), as modified by this
          section. The AAA’s rules are available at </span
        ><a href="http://www.adr.org"
          ><span style="color: #0563c1">www.adr.org</span></a
        ><span style="color: black"
          >. A form for initiating arbitration proceedings is available on the
          AAA’s site at </span
        ><a href="http://www.adr.org"
          ><span style="color: #0563c1">http://www.adr.org</span></a
        ><span style="color: black">. </span>
      </p>

      <p class="pl-8">
        <span style="color: black"
          >v.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span style="color: black"
          >You and We will select the arbitrator, and if you and We are unable
          to reach agreement on selection of the arbitrator within 30 days after
          the notice of arbitration is served, then the AAA will select the
          arbitrator. Arbitration will not commence until the party requesting
          arbitration has deposited U.S. $1,000 with the arbitrator for the
          arbitrator’s fees and costs. Subject to the foregoing, payment of all
          filing, administration and arbitrator fees will be governed by the
          AAA's applicable consumer rules. The parties shall be responsible for
          their own attorneys' fees and costs in arbitration, unless they are
          authorized by law or the arbitrator determines that a claim was
          frivolous or brought for an improper purpose or in bad faith.</span
        >
      </p>

      <p class="pl-8">
        <span style="color: black"
          >vi.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span style="color: black"
          >The arbitration hearing will commence within sixty (60) days after
          the appointment of the arbitrator and the hearing will be completed
          and an award rendered in writing within sixty (60) days after the
          commencement of the hearing. Prior to the hearing, each party will
          have the right to take up to four (4) evidentiary depositions, and
          exchange two (2) sets of document production requests and two sets,
          each, of not more than ten (10) interrogatories.</span
        >
      </p>

      <p class="pl-8">
        <span style="color: black"
          >vii.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span style="color: black"
          >The arbitrator will decide the substance of all claims exclusively in
          accordance with the laws of the State of Florida, including recognized
          principles of equity, and will honor all claims of privilege
          recognized by law. The arbitrator will not be bound by rulings in
          prior arbitrations involving our other users, but is bound by rulings
          in prior arbitrations involving the same user to the extent required
          by applicable law.
        </span>
      </p>

      <p class="pl-8">
        <span style="color: black"
          >viii.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span style="color: black"
          >Judgment on any award rendered by the arbitrator is final, binding
          and conclusive on you and us and your and our respective
          administrators, executors, legal representatives, successors and
          assigns, and may only be entered in the state or federal courts of
          record for Palm Beach County, Florida.
        </span>
      </p>

      <p class="pl-8">
        <span style="color: black"
          >ix.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><span style="color: black"
          >With the exception of disclosures to affiliates and legal counsel,
          all negotiations and arbitration proceedings related to a dispute
          (including a settlement, award, or the documents and briefs exchanged
          or produced during arbitration) are confidential and may not be
          disclosed by the parties except to the extent necessary for interim
          measures or conservatory relief, the enforcement of an arbitration
          award, or as required by law.</span
        >
      </p>

      <p>
        <span style="color: black"
          >c.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><u
          ><span style="color: black"
            >Prohibition of Class and Representative Actions and
            Non-Individualized Relief</span
          ></u
        ><span style="color: black"
          >.
          <b
            >YOU AND WE AGREE THAT EACH OF US MAY BRING CLAIMS AGAINST THE OTHER
            ONLY ON AN INDIVIDUAL BASIS AND NOT AS PART OF ANY PURPORTED CLASS
            OR REPRESENTATIVE ACTION OR PROCEEDING. UNLESS BOTH YOU AND WE AGREE
            OTHERWISE IN WRITING, THE ARBITRATOR MAY NOT CONSOLIDATE OR JOIN
            MORE THAN ONE PERSON’S OR PARTY’S CLAIMS, AND MAY NOT OTHERWISE
            PRESIDE OVER ANY FORM OF A CONSOLIDATED, REPRESENTATIVE OR CLASS
            PROCEEDING. ALSO, THE ARBITRATOR MAY AWARD RELIEF (INCLUDING
            MONETARY, INJUNCTIVE AND DECLARATORY RELIEF) ONLY IN FAVOR OF THE
            INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO THE EXTENT NECESSARY TO
            PROVIDE RELIEF NECESSITATED BY THAT PARTY’S INDIVIDUAL CLAIM(S). ANY
            RELIEF AWARDED CANNOT AFFECT OTHER USERS.&nbsp;THEREFORE, YOU DO NOT
            HAVE THE OPPORTUNITY TO GO TO COURT TO ASSERT OR DEFEND YOUR RIGHTS
            AND YOU GIVE UP YOUR RIGHT TO PARTICIPATE IN OR BRING CLASS ACTIONS.
            BY USING THE SERVICES, YOU CONSENT TO THESE RESTRICTIONS.</b
          ></span
        >
      </p>

      <p>
        <span style="color: black"
          >d.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span
        ><u
          ><span style="color: black"
            >Judicial Forum for Legal Disputes</span
          ></u
        ><span style="color: black"
          >. Unless you and we agree otherwise in writing, in the event that any
          provision of this section is found not to apply to you or to a
          particular claim or dispute as a result of a decision by the
          arbitrator or a court order, any claim or dispute that has arisen or
          may arise between you and us must be resolved exclusively by a state
          or federal court located in Palm Beach County, Florida. You and we
          will submit to the personal jurisdiction of the courts located within
          Palm Beach County, Florida for the purpose of litigating all such
          claims or disputes.
        </span>
      </p>

      <p>
        <a name="_heading=h.4d34og8"></a
        ><span style="color: black">21.<span>&nbsp;&nbsp; </span></span
        ><b
          ><u><span style="color: black">MISCELLANEOUS</span></u></b
        ><span style="color: black"
          >. You may not assign these Terms or your rights to use all or any
          portion of the Services without Our prior written consent. No delay or
          omission by Us to exercise any right or power under these Terms will
          impair any such right or power or be construed as a waiver thereof. A
          waiver by Us in any one instance of any of the covenants, conditions
          or agreements to be performed by you will not be construed as a waiver
          with respect to any succeeding instance in which the same provision
          may apply. We may update, amend or change these Terms at any time,
          provided that, if you are an Artist or a Registered Fan, then we will
          alert you of any material changes via a pop-up prior to or while you
          are accessing your Application account, or by email, in our
          discretion. In all cases, your continued use of the Services after we
          make changes is deemed to be acceptance of all changes. The headings
          contained in these Terms are for convenience of reference only, are
          not to be considered a part of these Terms and will not limit or
          otherwise affect in any way its meaning or interpretation. These Terms
          are for the sole benefit of the parties and their respective permitted
          successors and permitted assigns and nothing herein, express or
          implied, is intended to or will confer upon any other person any legal
          or equitable right, benefit or remedy of any nature whatsoever, under
          or by reason of these Terms. If any provision of these Terms is found
          by any court or administrative body of competent jurisdiction to be
          invalid or unenforceable, such invalidity or unenforceability will not
          affect the other provisions of these Terms, which will remain in full
          force and effect. These Terms represent the entire understanding and
          agreement between Us and you with respect to the subject matter
          hereof, and supersede all other negotiations, understandings and
          representations (if any) made by and between such parties, whether
          orally or in writing.</span
        >
      </p>

      <p>
        <span style="color: black">22.<span>&nbsp;&nbsp; </span></span
        ><b
          ><u><span style="color: black">CONTACT INFORMATION</span></u></b
        ><span style="color: black">.</span><span> </span
        ><span style="color: black"
          >If you have any questions or need further information as to the
          Services, please contact us via email at:</span
        >
        support@philippesaisse.com.
      </p>

      <p
        style="
          margin-bottom: 0in;
          text-align: justify;
          line-height: normal;
          border: none;
        "
      >
        <span style="color: black">&nbsp;</span>
      </p>
    </div>
  </section>
</template>
<script>
import { useHead } from "@unhead/vue";
import { termsMetaTags } from "../meta/metaData";
export default {
  setup() {
    useHead({
      meta: termsMetaTags(),
    });
  },
  mounted() {
    document.body.classList.remove("bg-p-black");
    document.body.classList.add("bg-cream-white");
  },
};
</script>
<style scoped>
.terms-of-use p,
.terms-of-use ul {
  margin: 1.5rem 0;
}
.terms-of-use li {
  margin-left: 1rem;
}
.terms-of-use .no-margin {
  margin: 0 !important;
}
</style>
