<template>
  <div class="modal micromodal-slide" :id="modalId" aria-hidden="true">
    <div class="modal__overlay" tabindex="-1" data-micromodal-close>
      <div
        class="modal__container border-white border-4 bg-cream-white text-center my-8"
        role="dialog"
        aria-modal="true"
        :aria-labelledby="modalId + '-title'"
      >
        <div class="border-2 border-black">
          <div class="border-b-2 border-black flex justify-end">
            <div class="bg-dark-cream border-l-2 border-black p-3">
              <Close
                class="modal__close cursor-pointer"
                aria-label="Close modal"
                data-micromodal-close
              />
            </div>
          </div>
          <div class="p-4">
            <h4 class="text-xl font-extrabold uppercase tracking-wide">
              {{ title }}
            </h4>
            <p class="my-4" :id="modalId + '-content'">
              {{ description }}
            </p>
            <a
              v-if="actionBtnTxt && actionBtnUrl"
              :href="actionBtnUrl"
              class="btn yellow block text-center"
            >
              {{ actionBtnTxt }}
            </a>
            <div v-if="showApps" class="flex mt-4 justify-center">
              <a
                target="_blank"
                href="https://apps.apple.com/us/app/philippe-saisse/id6444134222"
              >
                <img
                  class="md:w-28 w-20 h-full md:mr-4 mr-2"
                  src="@/assets/images/app-store.png"
                  alt="Download on the App Store Icon"
                />
              </a>
              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.quevis.psaisse.dev"
              >
                <img
                  class="md:w-28 w-20 h-full"
                  src="@/assets/images/google-play.png"
                  alt="Get on Google Play Icon"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MicroModal from "micromodal";
import Close from "@/components/svg/Close.vue";
export default {
  components: {
    Close,
  },
  props: {
    modalId: {
      type: String,
      default: "modal-1",
    },
    title: {
      type: String,
      default: "Title",
    },
    description: {
      type: String,
      default: "Description here",
    },
    actionBtnTxt: {
      type: String,
      default: "",
    },
    actionBtnUrl: {
      type: String,
      default: "https://saissemedia.com/",
    },
    showApps: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    MicroModal.init();
  },
  mounted() {
    MicroModal.init();
  },
};
</script>
<style>
/**************************\
  Basic Modal Styles
\**************************/
.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal__container {
  /* background-color: #fff;
  padding: 30px;
  max-height: 100vh;
  border-radius: 4px; */
  overflow-y: auto;
  max-width: 500px;
  margin: 0 20px;
  /* box-sizing: border-box; */
}

.modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal__title {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.25;
  color: #00449e;
  box-sizing: border-box;
}

.modal__close {
  background: transparent;
  border: 0;
}

.modal__header .modal__close:before { content: "\2715"; }

.modal__content {
  margin-top: 2rem;
  margin-bottom: 2rem;
  line-height: 1.5;
  color: rgba(0,0,0,.8);
}

.modal__btn {
  font-size: .875rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  background-color: #e6e6e6;
  color: rgba(0,0,0,.8);
  border-radius: .25rem;
  border-style: none;
  border-width: 0;
  cursor: pointer;
  -webkit-appearance: button;
  text-transform: none;
  overflow: visible;
  line-height: 1.15;
  margin: 0;
  will-change: transform;
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  transition: -webkit-transform .25s ease-out;
  transition: transform .25s ease-out;
  transition: transform .25s ease-out,-webkit-transform .25s ease-out;
}

.modal__btn:focus, .modal__btn:hover {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.modal__btn-primary {
  background-color: #00449e;
  color: #fff;
}



/**************************\
  Demo Animation Style
\**************************/
@keyframes mmfadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}

@keyframes mmfadeOut {
    from { opacity: 1; }
      to { opacity: 0; }
}

@keyframes mmslideIn {
  from { transform: translateY(15%); }
    to { transform: translateY(0); }
}

@keyframes mmslideOut {
    from { transform: translateY(0); }
    to { transform: translateY(-10%); }
}

.micromodal-slide {
  display: none;
}

.micromodal-slide.is-open {
  display: block;
}

.micromodal-slide[aria-hidden="false"] .modal__overlay {
  animation: mmfadeIn .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="false"] .modal__container {
  animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__overlay {
  animation: mmfadeOut .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__container {
  animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
  will-change: transform;
}
</style>
