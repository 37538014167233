<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.7715 4.45753L12.0002 8.85667L8.229 4.45753L4.45776 8.22876L8.85691 12L4.45776 15.7712L8.229 19.5425L12.0002 15.1433L15.7715 19.5425L19.5427 15.7712L15.1436 12L19.5427 8.22876L15.7715 4.45753Z"
      fill="#101010"
    />
  </svg>
</template>
