import { createApp } from "vue";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";

import { createHead } from "@vueuse/head";

import App from "./App.vue";
import router from "./router";

import "./assets/main.css";

const app = createApp(App);
const head = createHead();

app.use(createPinia().use(piniaPluginPersistedstate));
app.use(router);
app.use(head);

// https://github.com/Maronato/vue-toastification#usage
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
app.use(Toast, {});

app.mount("#app");
