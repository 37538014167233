<script>
export default {
  props: {
    color: {
      type: String,
      default: "#1B160E",
    },
    width: {
      type: String,
      default: "40",
    },
    height: {
      type: String,
      default: "40",
    },
  },
};
</script>
<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.0001 4.16666C13.5001 4.16666 4.16675 13.5 4.16675 25C4.16675 36.5 13.5001 45.8333 25.0001 45.8333C36.5001 45.8333 45.8334 36.5 45.8334 25C45.8334 13.5 36.5001 4.16666 25.0001 4.16666ZM25.0001 10.4167C28.4584 10.4167 31.2501 13.2083 31.2501 16.6667C31.2501 20.125 28.4584 22.9167 25.0001 22.9167C21.5417 22.9167 18.7501 20.125 18.7501 16.6667C18.7501 13.2083 21.5417 10.4167 25.0001 10.4167ZM25.0001 40C19.7917 40 15.1876 37.3333 12.5001 33.2917C12.5626 29.1458 20.8334 26.875 25.0001 26.875C29.1459 26.875 37.4376 29.1458 37.5001 33.2917C34.8126 37.3333 30.2084 40 25.0001 40Z"
      :fill="color"
    />
  </svg>
</template>
