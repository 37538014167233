export function homeMetaTags() {
  return [
    {
      name: "description",
      content:
        "Subscriptions starting at just $5 a month. Unlimited access to Philippe’s music, technique, and more.",
    },
    // FB OG Tags
    {
      property: "og:url",
      content: "https://www.saissemedia.com",
    },
    {
      property: "og:type",
      content: "website",
    },
    {
      property: "og:title",
      content: "Philippe Saisse",
    },
    {
      property: "og:description",
      content:
        "Subscriptions starting at just $5 a month. Unlimited access to Philippe’s music, technique, and more.",
    },
    {
      property: "og:image",
      content: "/src/assets/images/bg-philippe.webp",
    },
    // Twitter Card Tags
    {
      property: "twitter:card",
      content: "/src/assets/images/bg-philippe.webp",
    },
    {
      property: "twitter:domain",
      content: "saissemedia.com",
    },
    {
      property: "twitter:url",
      content: "https://www.saissemedia.com",
    },
    {
      property: "twitter:title",
      content: "Philippe Saisse",
    },
    {
      property: "twitter:description",
      content:
        "Subscriptions starting at just $5 a month. Unlimited access to Philippe’s music, technique, and more.",
    },
  ];
}

export function showsMetaTags() {
  return [
    {
      name: "description",
      content:
        "Purchase tickets to in-person events as well as exclusive virtual events.",
    },
    // FB OG Tags
    {
      property: "og:url",
      content: "https://www.saissemedia.com/events",
    },
    {
      property: "og:type",
      content: "website",
    },
    {
      property: "og:title",
      content: "Philippe Saisse | Events",
    },
    {
      property: "og:description",
      content:
        "Purchase tickets to in-person events as well as exclusive virtual events..",
    },
    {
      property: "og:image",
      content: "/src/assets/images/ps-avatar-09.webp",
    },
    // Twitter Card Tags
    {
      property: "twitter:card",
      content: "/src/assets/images/ps-avatar-09.webp",
    },
    {
      property: "twitter:domain",
      content: "saissemedia.com",
    },
    {
      property: "twitter:url",
      content: "https://www.saissemedia.com/events",
    },
    {
      property: "twitter:title",
      content: "Philippe Saisse | Events",
    },
    {
      property: "twitter:description",
      content:
        "Purchase tickets to in-person events as well as exclusive virtual events..",
    },
  ];
}

export function codeMetaTags() {
  return [
    {
      name: "description",
      content:
        "Enter in the code that was sent to the email address you provided.",
    },
  ];
}

export function signInMetaTags() {
  return [
    {
      name: "description",
      content:
        "Sign back in to view your account, manage your subscription, and purchase access to Philippe’s events.",
    },
  ];
}

export function signUpMetaTags() {
  return [
    {
      name: "description",
      content:
        "Sign up today to make an account, start your subscription, and access all of Philippe’s exclusive content.",
    },
  ];
}

export function privacyMetaTags() {
  return [
    {
      name: "description",
      content:
        "Saisse Media, LLC is pleased to provide you with access to our Services. Read through our Privacy Policy carefully.",
    },
  ];
}

export function termsMetaTags() {
  return [
    {
      name: "description",
      content:
        "The Application has engaged with Philippe Saisse to deliver content and other Artist branded or endorsed materials to Philippe’s Registered Fans. ",
    },
  ];
}

export function createProfileMetaTags() {
  return [
    {
      name: "description",
      content:
        "Create your Philippe Saisse profile by entering in your information.",
    },
  ];
}
